import config, { ORIGIN } from "config";

const { BASE_URL } = config; // REACT_APP_BASE_API_URL_DEV;
export const BASE_URL_ATTACHMENT = BASE_URL;

const endPoints = {
  login: "userlogin",
  clientLogin: "client-login",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password-request",
  signup: "signup",
  adminRegister: "superAdmin-Register",
  logout: "logout",
  logoutAll: "logout-all",

  // common
  add: "add",
  edit: "edit",
  delete: "delete",
  getAll: "getall",
  getById: "getbyid",
  getSettingById: "get-setting-by-id",
  getAllByConfId: "getall-by-confid",
  getActivityReminder: "getactivityreminder",
  getAllPagination: "getall-pagination",
  getallPaginationV2: "getall-pagination-v2",
  attachmentByUniqueId: "attachment-by-uniqueid",
  bulkDelete: "Bulkdelete",
  dueToday: "dueToday",
  activityDismissAll: "dismiss-all-activity-reminder",
  updateTemplate: "update-template",
  getAllByUserId: "getall-by-userId",
  getSignedContacts: "get-signed-contacts",
  getByContactId: "get-by-contactid",
  getScheduledCalls: "get-scheduled-calls",
  getAllDuplicate: "getall-duplicate",
  assignedHistory: "assigned-history",
  // team endpoints
  addUser: "add-user",
  editUser: "edit-user",
  activeDeactive: "active-deactive",
  allActivityCount: "allactivitycount",
  dismissReminder: "dismiss-activityreminder-byid",
  getTemplateById: "get-template-by-id",
  updateConnection: "update-connection",
  updatePassword: "update-team-user-password",
  toggleUserByAdmin: "toggle-email-sms-by-admin",
  getAllClients: "get-all-clients",
  toggleClient: "toggle-client",
  activeInactive: "active-inactive",
  deleteClient: "remove-client",
  // roles
  getAllRolesWithPagination: "get-all-with-pagination",
  getAllUser: "get-all",
  getUserByUserRoleId: "getuserby-userroleid",
  getUserByRoleName: "getall-ByRoleName",
  getReadLaterChats: "get-read-later-chats",
  getReadChatInfo: "get-chat-read-users",
  // permissions
  getAllPermissions: "get-all",

  // other
  getAllScreens: "get-all-screens",
  getAllScreensWithPagination: "get-all",
  getAllWithPagination: "get-all",
  getAllScreensWithPermission: "get-all-screens-withpermission",
  getRoleAccess: "getby-roleid",
  getPermissionsByRoleId: "getby-roleid",
  savePreference: "save-preference",
  getPreference: "getdetail-by-userid",
  getbyid: "getbyid",
  getpayment: "get-payment",
  getCheckoutLinkInvoice: "get-checkout-link-invoice",
  getCardFee: "get-card-fee",
  activityComplete: "status-update",
  leadOwner: "changeSalesRepId",
  assignLeadOwner: "assign-lead-owner",
  removeLeadOwner: "remove-lead-owner",
  leadSource: "changeLeadSource",
  getAllCustomFields: "get-all-customs",
  getConversation: "get-all-pagination-by-contactId",
  getInboundOutbond: "get-inbound-outbound",
  getContactAnalytics: "ContactAnalytics",
  getAllTemplate: "get-all-templates",
  getAllVideoTemplates: "getall-template",
  sendMessage: "add",
  sendEmail: "add",
  sendEmailFormData: "send-email-form-data",
  addV1: "add-v1",
  getByUserId: "getall-wo-pagi-userid",
  importCSV: "import-csv",
  getEmailExist: "check-email-exist",
  updateColourUpdate: "update-colour",
  // files
  getAllfiles: "get-all-files-by",
  addfiles: "add-file",
  deletefiles: "deletefile",

  // draft mail

  emailDraft: "email-draft",
  sendEmailDraft: "send-email-draft",
  deleteEmailDraft: "delete-email-draft",
  editEmailDraft: "edit-email-draft",
  editEmailScheduled: "edit-email-scheduled",
  cancelScheduledEmail: "cancel-scheduled-email",

  // agreement
  addAgreement: "add-agreement",
  getAgreementByUrl: "get-agreement-by-url",
  updateAgreement: "update-agreement",
  getAgreementByContactId: "get-all-agreements",
  decrypted: "decrypt",
  getUploadAgreement: "upload-agreement",
  deleteAgreement: "delete-agreement",
  editAgreement: "update-agreement-subject",

  // chat
  getChatroomPagination: "get-chatroom-pagination",
  getAllChats: "get-chat-by",
  sendMessageChat: "send-message-chat",
  sendMessageGroup: "send-message-group",
  getRoomId: "get-chatroomid",
  getChatBySearch: "get-search-chat",
  getUnreadCount: "getall-unreadcount",
  getAllByMonth: "getall-by-month",
  createGroup: "create-group",
  editGroup: "rename-group",
  fetchMembers: "gel-all-members-by-groupid",
  deleteMessage: "delete-message",
  deleteChatroom: "delete-chatroom",
  editMessage: "edit-message",
  pinChat: "pin-chat",
  unPinChat: "unpin-chat",
  markUnread: "mark-unread",
  getAllChatFile: "get-all-files-by-room-id",

  // Attachments
  addFolders: "add-folder",
  getAllFolder: "getall-folder",
  getAttachmentsByUserId: "get-attachments-by-folderid",
  addAttachment: "add-attachment",
  editFolder: "update-folder",
  deleteAttachment: "delete-attachment",
  editAttachmentFile: "edit-attachment",

  // appointment setter
  addAS: "add",
  getAllUnAssignedAS: "getall-unassigned",
  getAllAssignAppointment: "getall",
  getAllCustomers: "getall-customers",
  getAllCustomerByContactId: "get-all-by-contactid",
  customerStatus: "customer-status",
  toggleAsEmailSms: "toggle-as-email-sms",

  // dnc
  dncAddV1: "add-v1",
  deletebycontactid: "delete-by-contactId",

  // token
  generateToken: "generate-token",
  validateToken: "validate-token",
  dncGetRoleId: "getall-by-roleid",
  // reports
  getAllReportsByUserId: "get-all-reports-byuserid",
  addReport: "add-report",
  getAllCallCampaign: "list-callcampaign-performance",
  getAllSalesPerformance: "agent-sales-report",
  getAllAsTimeStatus: "as-agent-timestatus",
  getAllAsAgent: "as-agent-list",
  getApprovedCustomer: "get-approved-customers",
  // customer

  addBulkCustomer: "import-client-customers",
  sendPayRequest: "submit-payment",
  submitCheckoutPayment: "submit-checkout-payment",
  bonusPayRequest: "bonus-payment",
  resendPayment: "resend-payment-link",
  getInvoiceSummary: "get-invoice-summary",
  uploadInvoice: "upload-invoice",
  updateSetter: "update-as",
  getPaymentLogs: "get-payment-logs",

  // contact
  invoice: "toggle-invoice-by-contactid",
  updatePdf: "update-pdf",
  updateCustomData: "edit-custom-field",
  addCustomData: "add-custom-field",
  videoVoiceAdd: "video-voice/add",
  videoVoiceEmail: "video-voice/send-email",
  addVideoVoiceTemplate: "add-video-voice-template",
  updateVideoVoiceTemplate: "edit-video-voice-template",
  getallLibrary: "getall-library",
  massEmailSend: "mass-email",
  massSMSSend: "mass-sms",
  changeDefaultCompany: "changeDefaultCompany",
  updateStatusById: "update-lead-status",
  getAllCustomsByUserId: "get-all-customs-by-userId",

  // sequence
  addSequenceTemplate: "add-sequence-template",
  testEmail: "testEmail",
  addSequenceContact: "addSequenceContact",
  getAllDaysBySeqId: "getallDays-By-SequenceId?sequenceId",
  getAllContactsSequenceById: "getallContact-Pagination-By-SequenceId",
  deleteSequenceTemplate: "deleteSequenceTemplate",
  deleteContact: "deleteContact",
  cloneSequence: "sequence-clone",
  editTemplate: "editTemplate",
  // Call-manager
  callManager: "call-manager",
  callMonitor: "call-monitor",
  callerIds: "getall-caller-ids",
  addCallerId: "add-caller-id",
  deleteCallerId: "delete-caller-id",
  disableEnable: "disable-enable-id",

  // DRIP CAMPAIGN
  addDripCampaignContact: "addDripCampaignContact",
  addDripLeadSourceGroup: "addDripLeadSourceGroup",
  deleteDripLeadSourceGroup: "deleteDripLeadSourceGroup",
  copycampaign: "copycampaign",
  stepSentCunt: "step-sent-count",
  contactStepSent: "contact-step-sent",
  getPostcardTypes: "get-postcard-types",

  getallContactPaginationByDripCampaignId:
    "getallContact-Pagination-By-DripCampaignId",

  // lead group
  getallDripLeadSourceGroupByDripCampaignId:
    "getallDripLeadSourceGroupByDripCampaignId",

  // Drip campaign folder
  addCampaignFolder: "addfolder",
  assignCampaignToFolder: "addfolder",
  getallCampaignFolder: "getall-folder",
  editCampaignFolder: "editfolder",
  deleteCampaignFolder: "deleteFolder",
  addCampaignInFolder: "add-folder-to-campaign",
  callLogList: "call-list",
  addPostCard: "add-post-card",
  addLetter: "add-letter",

  // virtual number
  searchVirtualNumber: "search-virtual-number",
  buyVirtualNumber: "buy-virtual-number",
  assignNumberToUser: "assign-number-to-user",
  unAssignNumberToUser: "unassign-number-to-user",
  unAssignNumberToClient: "unassign-number-to-client",
  getAllVirtualNumber: "getall-virtual-numbers",
  getAllVirtualNumberAsCIDs: "getall-all-numbers",
  getAllVirtualNumberByCompId: "getall-virtual-numbers",
  removeVirtualNumber: "remove-virtual-number",

  // call result
  updateCallResultSetting: "update-call-result-setting",
  updateCallSid: "update-call-sid",
  updateIncomingCallByCallSid: "update-incoming-call-by-call-sid",
  UpdateDispositionSid: "update-disposition",
  callNote: "update-note",
  holdUnhold: "update-conf",
  callEnd: "call-end",
  removeConference: "remove-participant",
  blindTransfer: "blind-transfer",
  getCallInfo: "get-callinfo",
  addCallSchedule: "add-call-schedule",
  toggleContactStatus: "toggle-email-sms",
  agentLogout: "logout-agent",
  getUserCommunicationAccess: "get-user-communication-access",
  getcompaniesbyemail: "get-companies-by-email",
  superAdminLogout: "logout-superadmin",
  clientLogout: "logout-client",
  timeLine: "get-as-timeline",
  callHangupAgent: "call-hangup-agent",
  requestPermission: "update-recording-transciption-request",
  startCallWrap: "start-call-wrap",
  dashboardDealCard: "get-dashboard-count",
  getOnlineUserList: "get-onlineuser-list",
  getOnlineAgents: "get-client-agent-list",
  getDashboardDealCount: "get-dashboard-deal-count",
  getDashboardMainCard: "get-dashboard-top-card",
  getComunication: "get-comunication-counts",
  globalSearch: "global-search",
  globalSearchByType: "search-by-type",
  globalSearchList: "global-search-pagination",
  getAgentCallBySid: "get-agentcall-sid",
  getMaxCall: "get-max-call",
  getMaxCallContact: "get-max-call-contact",
  getallDispositionOrderby: "getallDisposition-orderby",
  getSavedCards: "get-saved-cards",
  deleteInvoice: "delete-invoice",
  getallmissedcalls: "getall-missed-calls",
  missedCallDismissAll: "dismiss-missed-call",
  dismissAllMissedCalls: "dismiss-all-missed-call",
  assignSignature: "assign-signature",
  deleteSignature: "delete-signature",
  updateCallNote: "update-call-note",
  getContactDetailsForCustomer: "get-contact-details-for-customer",
  // Dashboard
  getDashboardCount: "get-dashboard-count-role",
  getMemberConnections: "get-member-connections",
  whoIsTyping: "typing",
  updateCallMessage: "update-type",
  getDashboardCountAgentPerformance: "get-dashboard-count-agent-performance",

  // Payment gateway
  getActiveDeactive: "active-deactive",
  getByCompanyId: "get-by-companyid",
  disable: "disable",
  addPaymentItem: "add-payment",
  addTermsAndConditions: "add-terms-and-conditions",

  // Payment Setting
  getInvoiceSetting: "getInvoiceSetting",
  addSettings: "add-settings",

  // CallingScript
  getCallingScript: "add-script",

  // emailConfiguration
  // getEmailConfiguration:"add"
  getPowerDialSetting: "get-setting-by-user-id",
  getContactsByMyListId: "get-contact-by-mylist-id",
  conversationEmailV1: "conversation-email-v1",
  setDefault: "set-default",
  signatureDropDown: "getall-signature-drop",
  fileUpload: "upload-file",

  // notes
  addNotes: "add-notes",
  editNotes: "edit-notes",

  getAccounts: "get-account",
  addAccount: "add-account",
  deleteAccount: "delete-account",
  // get upcoming activities
  getTodayUpcomingActivities: "get-today-upcoming-activities",
  importHistoryUndo: "undo-customer",
  readChatById: "get-read-chat-by-chatroomid",
  assignToChatToContact: "assign-group-to-contact",
  unassignToChatToContact: "unassign-group-to-contact",
  getallCampaignClients: "getall-campaign-clients",
  getallAsByClientId: "getall-as-by-clientid",

  companyChange: "company-change",
  asForAssign: "getall-for-assign",

  // contactForm

  getAllContactForm: "get-all-contactform",
  addContactForm: "add-contactForm",
  getContactFormByUrl: "get-contactform-by-url",
  submitContactForm: "submit-contactForm",
  deleteContactForm: "delete-ContactForm",
};

const API_URLS = {
  LOGIN: `${BASE_URL}/user-login/${endPoints.login}`,
  CLIENT_LOGIN: `${BASE_URL}/user-login/${endPoints.clientLogin}`,
  FORGOT_PASSWORD: `${BASE_URL}/user-login/${endPoints.forgotPassword}`,
  RESET_PASSWORD: `${BASE_URL}/user-login/${endPoints.resetPassword}`,
  ADMIN_SIGNUP: `${BASE_URL}/user-login/${endPoints.adminRegister}`,
  LOGOUT: `${BASE_URL}/user-login/${endPoints.logout}`,
  ALL_LOGOUT: `${BASE_URL}/user-login/${endPoints.logoutAll}`,
  ADD_ACCOUNT: `${BASE_URL}/user-login/${endPoints.addAccount}`,
  GET_ACCOUNTS: `${BASE_URL}/user-login/${endPoints.getAccounts}`,
  DELETE_ACCOUNT: `${BASE_URL}/user-login/${endPoints.deleteAccount}`,

  // Call manager
  // GET_LIVE_CALL_SUGGESTIONS: `${BASE_URL}/${endPoints.callManager}/triggers/get-recommendations`,
  // GET_CALL_LOGS: `${BASE_URL}/Transcript/call-logs`,
  GET_CALL_LOGS: `${BASE_URL}/${endPoints.callManager}/call-logs`,
  TWILIO_TOKEN: `${BASE_URL}/${endPoints.callManager}/call-token`,
  UPDATE_MODULE: `${BASE_URL}/${endPoints.callManager}/update-module`,
  CALL_MONITOR: `${BASE_URL}/call-manager/${endPoints.callMonitor}`,
  ACTIVE_AGENT_LIST: `${BASE_URL}/${endPoints.callManager}/sales-agent-list`,
  ADMIN_ACCORDIAN_LIST: `${BASE_URL}/${endPoints.callManager}/admin-agent-list`,

  // team endpoints
  ADD_USER: `${BASE_URL}/user/${endPoints.addUser}`,
  EDIT_USER: `${BASE_URL}/user/${endPoints.editUser}`,
  ACTIVE_DEACTIVE: `${BASE_URL}/user/${endPoints.activeDeactive}`,
  FETCH_USERS: `${BASE_URL}/user/${endPoints.getAllPagination}`,
  DELETE_USER: `${BASE_URL}/user/${endPoints.delete}`,
  GET_USEROLESBY_USERID: `${BASE_URL}/user/${endPoints.getUserByUserRoleId}`,
  GET_USERS_BY_ROLE_NAME: `${BASE_URL}/user/${endPoints.getUserByRoleName}`,
  GET_ALL_USERS: `${BASE_URL}/user/${endPoints.getAll}`,
  UPDATE_CONNECTION: `${BASE_URL}/user/${endPoints.updateConnection}`,
  UPDATE_PASSWORD: `${BASE_URL}/user-login/${endPoints.updatePassword}`,
  TOGGLE_USER_BY_ADMIN: `${BASE_URL}/user/${endPoints.toggleUserByAdmin}`,
  GET_USER_BY_ID: `${BASE_URL}/user/${endPoints.getbyid}`,
  GET_ROOM_DETAILS: `${BASE_URL}/chat/${endPoints.getMemberConnections}`,
  POST_WHO_IS_TYPING: `${BASE_URL}/chat/${endPoints.whoIsTyping}`,
  UPDATE_CALL_MESSAGE: `${BASE_URL}/chat/${endPoints.updateCallMessage}`,
  MARK_UNREAD: `${BASE_URL}/chat/${endPoints.markUnread}`,
  GET_ALL_READ_LATER: `${BASE_URL}/chat/${endPoints.getReadLaterChats}`,
  GET_ALL_READ_CHAT_INFO: `${BASE_URL}/chat/${endPoints.getReadChatInfo}`,

  // contacts endpoints
  ADD_CONTACT: `${BASE_URL}/contact/${endPoints.add}`,
  EDIT_CONTACT: `${BASE_URL}/contact/${endPoints.edit}`,
  FETCH_CONTACT: `${BASE_URL}/contact/GetAll-v1`,
  FETCH_CLIENTS: `${BASE_URL}/contact/${endPoints.getAllClients}`,
  TOGGLE_CLIENT: `${BASE_URL}/contact/${endPoints.toggleClient}`,
  DELETE_CLIENT: `${BASE_URL}/contact/${endPoints.deleteClient}`,
  TOGGLE_CLIENT_STATUS: `${BASE_URL}/contact/${endPoints.activeInactive}`,
  GET_CONTACT_MY_LIST_ID: `${BASE_URL}/contact/${endPoints.getContactsByMyListId}`,
  UPDTAE_COLOR_CONTACT: `${BASE_URL}/contact/${endPoints.updateColourUpdate}`,

  FETCH_CONTACT_V2: `${BASE_URL}/contact/GetAll-v2`,
  SEARCH_CONTACT: `${BASE_URL}/contact/search-contact`,
  FETCH_CONTACTS_USERID: `${BASE_URL}/contact/${endPoints.getAllByUserId}`,
  FETCH_SIGNED_CONTACT: `${BASE_URL}/contact/${endPoints.getSignedContacts}`,
  TOGGLE_INVOICE: `${BASE_URL}/contact/${endPoints.invoice}`,
  GET_ALL_DUPLICATE: `${BASE_URL}/contact/${endPoints.getAllDuplicate}`,

  DELETE_CONTACT: `${BASE_URL}/contact/${endPoints.delete}`,
  GET_CONTACT_BY_ID: `${BASE_URL}/contact/${endPoints.getbyid}`,
  GET_CONTACT_BY_SEARCH: `${BASE_URL}/contact/${endPoints.getAll}`,
  BULK_DELETE: `${BASE_URL}/contact/${endPoints.bulkDelete}`,
  BULK_IMPORT: `${BASE_URL}/bulkContactController/${endPoints.importCSV}`,
  UPDATE_LEAD_OWNER: `${BASE_URL}/contact/${endPoints.leadOwner}`,
  ASSIGN_LEAD_OWNER: `${BASE_URL}/contact/${endPoints.assignLeadOwner}`,
  REMOVE_LEAD_OWNER_BY_ID: `${BASE_URL}/contact/${endPoints.removeLeadOwner}`,
  UPDATE_LEAD_SOURCE: `${BASE_URL}/contact/${endPoints.leadSource}`,
  EDIT_CONTRACT: `${BASE_URL}/contract/${endPoints.edit}`,
  ADD_CONTRACT: `${BASE_URL}/contract/${endPoints.add}`,
  ASSIGNED_HISTORY: `${BASE_URL}/contact/${endPoints.assignedHistory}`,
  GET_CONTACT_DETAILS_FOR_CUSTOMER: `${BASE_URL}/contact/${endPoints.getContactDetailsForCustomer}`,

  // address endpoints
  ADD_ADDRESS: `${BASE_URL}/address/${endPoints.add}`,
  EDIT_ADDRESS: `${BASE_URL}/address/${endPoints.edit}`,

  // Import Contact FILE
  ADD_IMPORTCONTACT: `${BASE_URL}/importcontactfile/${endPoints.add}`,
  SUBMIT_IMPORTCONTACT: `${BASE_URL}/importcontactfile/submit`,
  GETALL_IMPORT_HISTORY: `${BASE_URL}/importcontactfile/${endPoints.getAll}`,
  GET_BY_ID_IMPORT_HISTORY: `${BASE_URL}/importcontactfile/${endPoints.getbyid}`,
  TABLE_SCHEMA: `${BASE_URL}/importcontactfile/tableSchema`,
  GETALL_MAPPING: `${BASE_URL}/importcontactfile/getall-mapping`,
  CSV_COLUMNS: `${BASE_URL}/importcontactfile/csvData`,
  DELETE_IMPORT: `${BASE_URL}/importcontactfile/${endPoints.delete}`,
  UNDO_IMPORT: `${BASE_URL}/importcontactfile/undo-contact`,
  FETCH_ALL_IMPORT_HISTORY: `${BASE_URL}/importcontactfile/${endPoints.getAll}`,
  DOWLOAD_SKIP_UPDATE: `${BASE_URL}/importcontactfile/download-skipped-updated-records`,

  // CONTACT CAMPAIGN
  FETCH_ALL_CONTACT_CAMPAIGN: `${BASE_URL}/contact-campaign/${endPoints.getAll}`,
  FETCH_ALL_CONTACT_CAMPAIGN_BY_ID: `${BASE_URL}/contact-campaign/${endPoints.getbyid}`,
  DELETE_CONTACT_CAMPAIGN: `${BASE_URL}/contact-campaign/delete-contact-campaign`,
  EDIT_CONTACT_CAMPAIGN: `${BASE_URL}/contact-campaign/${endPoints.edit}`,

  // ImportContactCampFILE
  GET_CAMPAIGN_SETTING_BY_ID: `${BASE_URL}/importedcustomerfiless/${endPoints.getSettingById}`,

  ADD_IMPORTCONTACTCAMP: `${BASE_URL}/importedcustomerfiless/${endPoints.add}`,
  SUBMIT_IMPORTCONTACTCAMP: `${BASE_URL}/importedcustomerfiless/submit`,
  TABLE_SCHEMACAMP: `${BASE_URL}/importedcustomerfiless/tableSchema`,
  GETALL_MAPPINGCAMP: `${BASE_URL}/importedcustomerfiless/getall-mapping`,
  CSV_COLUMNSCAMP: `${BASE_URL}/importedcustomerfiless/csvColumns`,
  DELETE_IMPORTCAMP: `${BASE_URL}/importedcustomerfiless/delete`,
  FETCH_ALL_IMPORT_HISTORYCAMP: `${BASE_URL}/importedcustomerfiless/${endPoints.getAll}`,
  DOWLOAD_SKIP_UPDATECAMP: `${BASE_URL}/importedcustomerfiless/download-skipped-updated-records`,

  // notes endpoints
  ADD_NOTE: `${BASE_URL}/notes/${endPoints.addNotes}`,
  EDIT_NOTE: `${BASE_URL}/notes/${endPoints.editNotes}`,
  DELETE_NOTE: `${BASE_URL}/notes/${endPoints.delete}`,
  FETCH_NOTES: `${BASE_URL}/notes/${endPoints.getAll}`,
  FETCH_NOTES_PAGINATION: `${BASE_URL}/notes/${endPoints.getAllPagination}`,

  // screens endpoints
  FETCH_SCREENS: `${BASE_URL}/screen/${endPoints.getAllScreensWithPagination}`,
  ADD_SCREEN: `${BASE_URL}/screen/${endPoints.add}`,
  EDIT_SCREEN: `${BASE_URL}/screen/${endPoints.edit}`,
  DELETE_SCREEN: `${BASE_URL}/screen/${endPoints.delete}`,

  // pipelines endpoints
  FETCH_PIPELINES: `${BASE_URL}/pipeline/${endPoints.getAllPagination}`,
  FETCH_PIPELINES_WITHOUT_PAGINATION: `${BASE_URL}/pipeline/${endPoints.getAll}`,
  ADD_PIPELINE: `${BASE_URL}/pipeline/${endPoints.add}`,
  EDIT_PIPELINE: `${BASE_URL}/pipeline/${endPoints.edit}`,
  DELETE_PIPELINE: `${BASE_URL}/pipeline/${endPoints.delete}`,

  // permissions
  GET_PERMISSIONS: `${BASE_URL}/permission/${endPoints.getAllPagination}`,
  ADD_PERMISSION: `${BASE_URL}/permission/${endPoints.add}`,
  EDIT_PERMISSION: `${BASE_URL}/permission/${endPoints.edit}`,
  DELETE_PERMISSION: `${BASE_URL}/permission/${endPoints.delete}`,

  // user access endpoints
  FETCH_USER_ACCESS: `${BASE_URL}/userscreenaccess/${endPoints.getRoleAccess}`,
  ADD_USER_ACCESS: `${BASE_URL}/userscreenaccess/${endPoints.add}`,
  GET_PERMISSION_SCREEN_CODE: `${BASE_URL}/userscreenaccess/get-permissions-by-screen-codes`,

  // roles
  GET_ROLES_PAGINATION: `${BASE_URL}/user-role/${endPoints.getAllPagination}`,
  ADD_ROLE: `${BASE_URL}/user-role/${endPoints.add}`,
  EDIT_ROLE: `${BASE_URL}/user-role/${endPoints.edit}`,
  DELETE_ROLE: `${BASE_URL}/user-role/${endPoints.delete}`,

  // Lead source
  GET_LEAD_SOURCE_PAGINATION: `${BASE_URL}/leadsource/${endPoints.getAllPagination}`,
  GET_LEAD_SOURCE_WITHOUT_PAGINATION: `${BASE_URL}/leadsource/${endPoints.getAll}`,
  ADD_LEAD_SOURCE: `${BASE_URL}/leadsource/${endPoints.add}`,
  EDIT_LEAD_SOURCE: `${BASE_URL}/leadsource/${endPoints.edit}`,
  DELETE_LEAD_SOURCE: `${BASE_URL}/leadsource/${endPoints.delete}`,

  // Lead status
  GET_LEAD_STATUS_PAGINATION: `${BASE_URL}/leadstatus/${endPoints.getAllPagination}`,
  GET_LEAD_STATUS_WITHOUT_PAGINATION: `${BASE_URL}/leadstatus/${endPoints.getAll}-ascending`,
  ADD_LEAD_STATUS: `${BASE_URL}/leadstatus/${endPoints.add}`,
  EDIT_LEAD_STATUS: `${BASE_URL}/leadstatus/${endPoints.edit}`,
  DELETE_LEAD_STATUS: `${BASE_URL}/leadstatus/${endPoints.delete}`,
  UPDATE_LEAD_STATUS_BY_ID: `${BASE_URL}/contact/${endPoints.updateStatusById}`,

  // tags
  GET_TAGS_PAGINATION: `${BASE_URL}/tag/${endPoints.getAllPagination}`,
  GET_TAGS_WITHOUT_PAGINATION: `${BASE_URL}/tag/${endPoints.getAll}`,
  GET_TAGS_BY_CONTACT_ID: `${BASE_URL}/tag/tagByContactId`,
  ADD_TAGS: `${BASE_URL}/tag/${endPoints.add}`,
  EDIT_TAGS: `${BASE_URL}/tag/${endPoints.edit}`,
  DELETE_TAGS: `${BASE_URL}/tag/${endPoints.delete}`,

  // templates
  GET_TEMPLATES: `${BASE_URL}/template/${endPoints.getAllPagination}`,
  GET_TEMPLATES_WITHOUT_PAGINATION: `${BASE_URL}/template/${endPoints.getAllTemplate}`,
  GET_TEMPLATES_VIDEO: `${BASE_URL}/template/${endPoints.getAllVideoTemplates}`,
  ADD_TEMPLATES: `${BASE_URL}/template/${endPoints.add}`,
  EDIT_TEMPLATES: `${BASE_URL}/template/${endPoints.updateTemplate}`,
  DELETE_TEMPLATES: `${BASE_URL}/template/${endPoints.delete}`,
  DELETE_TEMPLATE_BY_ID: `${BASE_URL}/template/${endPoints.getTemplateById}`,
  DELETE_TEMPLATE_SINGLE_ID: `${BASE_URL}/template/${endPoints.delete}`,
  ADD_EMAIL_SIGNATURE: `${BASE_URL}/userplatformemail/${endPoints.assignSignature}`,
  DELETE_SIGNATURE_SINGLE_ID: `${BASE_URL}/userplatformemail/${endPoints.deleteSignature}`,

  // contact form
  DELETE_CONTACT_FORM_SINGLE_ID: `${BASE_URL}/ContactForm/delete-ContactForm`,

  // tag contact
  ADD_TAG_CONTACT: `${BASE_URL}/tagContact/${endPoints.add}`,
  DELETE_TAG_CONTACT: `${BASE_URL}/tagContact/${endPoints.delete}`,

  // activities
  GET_ACTIVITIES_TYPE: `${BASE_URL}/activityType/${endPoints.getAll}`,
  ADD_ACTIVITY: `${BASE_URL}/activity/${endPoints.add}`,
  UPLOAD_FILE: `${BASE_URL}/activity/${endPoints.fileUpload}`,
  EDIT_ACTIVITY: `${BASE_URL}/activity/${endPoints.edit}`,
  DELETE_ACTIVITY: `${BASE_URL}/activity/${endPoints.delete}`,
  ACTIVATE_ACTIVITY: `${BASE_URL}/activity/${endPoints.activityComplete}`,
  FETCH_ACTIVITIES_PAGINATION: `${BASE_URL}/activity/${endPoints.getAllPagination}`,
  FETCH_UPCOMING_ACTIVITIES_PAGINATION: `${BASE_URL}/activity/${endPoints.getTodayUpcomingActivities}`,
  FETCH_ACTIVITIES_PAGINATION_USERID: `${BASE_URL}/activity/${endPoints.getAllPagination}-userid`,
  FETCH_ACTIVITIES_BY_USERID: `${BASE_URL}/activity/${endPoints.getByUserId}`,
  FETCH_ACTIVITIES_PAGINATION_USERID_BY_MONTH: `${BASE_URL}/activity/${endPoints.getAllByMonth}`,
  FETCH_ACTIVITIES_DUETODAY: `${BASE_URL}/activity/${endPoints.dueToday}`,
  GET_ALL_ACTIVITIES: `${BASE_URL}/activity/${endPoints.getAll}`,
  GET_ALL_COUNTS: `${BASE_URL}/activity/${endPoints.allActivityCount}`,
  GET_ACTIVITIES_REMINDER: `${BASE_URL}/ActivityReminder/${endPoints.getActivityReminder}`,
  DISMISS_REMINDER: `${BASE_URL}/ActivityReminder/${endPoints.dismissReminder}`,
  UPDATE_READ_REMINDER: `${BASE_URL}/ActivityReminder/update-read-reminder`,
  DISMISS_REMINDER_ALL: `${BASE_URL}/ActivityReminder/${endPoints.activityDismissAll}`,
  FETCH_ALL_CALL_LOGS: `${BASE_URL}/call-logs/${endPoints.getAll}`,
  UPDATE_CALL_LOGS: `${BASE_URL}/call-logs/update-clientId`,
  FETCH_ALL_CALL_LOGS_SETTINGS: `${BASE_URL}/call-logs/getall-v1`,
  ADD_UPLOAD_AGREEMENT: `${BASE_URL}/Agreement/${endPoints.getUploadAgreement}`,
  FETCH_All_CAMPAIGN_CLIENT: `${BASE_URL}/call-logs/${endPoints.getallCampaignClients}`,
  FETCH_All_AS_BY_CLIENT_ID: `${BASE_URL}/call-logs/${endPoints.getallAsByClientId}`,

  // notifications
  GET_NOTIFICATIONS: `${BASE_URL}/notification/${endPoints.getAll}`,
  GET_EMAIL_NOTIFICATIONS: `${BASE_URL}/notification/get-email-notifications`,
  GET_SMS_NOTIFICATIONS: `${BASE_URL}/notification/get-sms-notifications`,
  REMOVE_NOTIFICATIONS: `${BASE_URL}/notification/remove-notification`,
  UPDATE_READ_NOTIFICATIONS: `${BASE_URL}/notification/update-read-notification`,
  REMOVE_ALL_NOTIFICATIONS: `${BASE_URL}/notification/remove-notifications-by-userid`,

  // Custom fields
  ADD_CUSTOM_FIELDS: `${BASE_URL}/custom/${endPoints.add}`,
  DELETE_CUSTOM_FIELDS: `${BASE_URL}/custom/${endPoints.delete}`,
  GET_CUSTOM_FIELDS: `${BASE_URL}/custom/${endPoints.getAll}`,
  GET_CUSTOM_FIELDS_BY_USERID: `${BASE_URL}/custom/${endPoints.getAllCustomsByUserId}`,
  UPDATE_CUSTOM_FIELDS: `${BASE_URL}/custom/${endPoints.updateCustomData}`,
  ADD_CUSTOM_FIELDS_SINGLE: `${BASE_URL}/custom/${endPoints.addCustomData}`,
  GET_ALL_CUSTOM_FIELDS: `${BASE_URL}/custom/${endPoints.getAllCustomFields}`,

  // Mylist
  ADD_MYLIST: `${BASE_URL}/mylist/${endPoints.add}`,
  ADD_LIST: `${BASE_URL}/mylist/add-list`,
  EDIT_LIST: `${BASE_URL}/mylist/edit`,
  REMOVE_LIST_CONTACT: `${BASE_URL}/mylist/remove-mylist-contacts`,
  GET_MYLIST: `${BASE_URL}/mylist/${endPoints.getAll}`,
  DELETE_MYLIST: `${BASE_URL}/mylist/${endPoints.delete}`,
  GET_MYLISTCONTACT_BY_MYLISTID: `${BASE_URL}/mylist/getll-mylistcontact-by-mylistId`,
  GET_MYLISTCONTACT_BY_MULTI_MYLISTID: `${BASE_URL}/mylist/getall-mylistcontact-by-multi-MylistId`,

  // other
  GET_PERMISSIONS_BY_ROLE_ID: `${BASE_URL}/userscreenaccess/${endPoints.getPermissionsByRoleId}`,
  GET_ROLES: `${BASE_URL}/user-role/${endPoints.getAll}`,
  FETCH_SCREENS_WITHOUT_PAGINATION: `${BASE_URL}/user-screen-access/${endPoints.getAllScreens}`,
  FETCH_SCREENS_WITH_PERMISSION: `${BASE_URL}/screen/${endPoints.getAllScreensWithPermission}`,
  ADD_PREFERENCE: `${BASE_URL}/preference/${endPoints.savePreference}`,
  GET_PREFERENCE: `${BASE_URL}/preference/${endPoints.getPreference}`,

  GET_CONVERSATION: `${BASE_URL}/conversation/${endPoints.getConversation}`,
  GET_CONTACT_ANALYTICS_CONTACT_ID: `${BASE_URL}/conversation/${endPoints.getContactAnalytics}`,
  GET_EMAIL_EXIST: `${BASE_URL}/contact/${endPoints.getEmailExist}`,
  GET_ALL_SCREEN_CODE: `${BASE_URL}/userscreenaccess/get-screen-codes-by-roleid`,
  GET_ALL_LOGS_CONVERSATION: `${BASE_URL}/conversation/${endPoints.getInboundOutbond}`,

  // send
  SEND_MESSAGE: `${BASE_URL}/text/${endPoints.sendMessage}`,
  ADD_DRAFT_MESSAGE: `${BASE_URL}/text/sms-draft`,
  SEND_DRAFT_MESSAGE: `${BASE_URL}/text/send-sms-draft`,
  EDIT_DRAFT_MESSAGE: `${BASE_URL}/text/edit-sms-draft`,

  DELETE_DRAFT_MESSAGE: `${BASE_URL}/text/delete-draft`,
  CANCEL_SCHEDULE_MESSAGE: `${BASE_URL}/text/cancel-scheduled-sms`,
  EDIT_SCHEDULER_MESSAGE: `${BASE_URL}/text/edit-sms-scheduled`,

  SEND_EMAIL: `${BASE_URL}/email/${endPoints.sendEmail}`,
  SEND_EMAIL_FORM_DATA: `${BASE_URL}/email/${endPoints.sendEmailFormData}`,
  SEND_EMAIL_V1: `${BASE_URL}/email/${endPoints.addV1}`,

  // draft mail

  ADD_TO_DRAFT_EMAIL: `${BASE_URL}/email/${endPoints.emailDraft}`,
  SEND_DRAFT_EMAIL: `${BASE_URL}/email/${endPoints.sendEmailDraft}`,
  EDIT_DRAFT_EMAIL: `${BASE_URL}/email/${endPoints.editEmailDraft}`,
  EDIT_EMAIL_SCHEDULE: `${BASE_URL}/email/${endPoints.editEmailScheduled}`,
  DELETE_DRAFT_EMAIL: `${BASE_URL}/email/${endPoints.deleteEmailDraft}`,
  DELETE_SCHEDULE_EMAIL: `${BASE_URL}/email/${endPoints.cancelScheduledEmail}`,
  SEND_MASS_EMAIL: `${BASE_URL}/email/${endPoints.massEmailSend}`, //
  SYNC_EMAILS: `${BASE_URL}/email/getall-pagination-v2-email-service`,
  SYNC_ALL_CONVERSATION: `${BASE_URL}/email/getall-pagination-v2-email-service-all`,
  SYNC_EMAIL_All: `${BASE_URL}/email/getall-by-user-email-service`,

  SEND_MASS_SMS: `${BASE_URL}/text/${endPoints.massSMSSend}`, //
  SEND_MMS: `${BASE_URL}/text/mms`,
  SEND_MAILS: `${BASE_URL}/payment/send-email`,
  CONVERT_HTML: `${BASE_URL}/pdf/convert-html`,

  GET_EMAIL_PAGINATION: `${BASE_URL}/email/${endPoints.getAllPagination}`,
  GET_EMAIL_PAGINATION_V2: `${BASE_URL}/email/${endPoints.getallPaginationV2}`,
  GET_ATTACHMENT_BY_UNIQUE_ID: `${BASE_URL}/email/${endPoints.attachmentByUniqueId}`,
  GET_SMS_PAGINATION: `${BASE_URL}/text/${endPoints.getAllPagination}`,
  USER_PLATFORM_EMAIL: `${BASE_URL}/userplatformemail/${endPoints.getAll}`,
  USER_PLATFORM_SETDEFAULT: `${BASE_URL}/userplatformemail/${endPoints.setDefault}`,

  // deal
  ADD_DEAL: `${BASE_URL}/deal/${endPoints.add}`,
  GET_DEAL: `${BASE_URL}/deal/${endPoints.getAll}`,
  EDIT_DEAL: `${BASE_URL}/deal/${endPoints.edit}`,
  UPDATE_LEAD_STATUS: `${BASE_URL}/deal/updateLeadStatus`,
  GET_ALL_LEADSTATUS_ASCENDING: `${BASE_URL}/deal/getall-leadstatus-ascending`,
  DELETE_DEAL_BY_ID: `${BASE_URL}/deal/${endPoints.delete}`,
  WON_LOST_LEAD: `${BASE_URL}/deal/wonLostLead`,

  // agreement
  ADD_AGREEMENT: `${BASE_URL}/Agreement/${endPoints.addAgreement}`,
  GET_AGREEMENT_URL: `${BASE_URL}/Agreement/${endPoints.getAgreementByUrl}`,
  GET_AGREEMENT_ID: `${BASE_URL}/Agreement/${endPoints.getbyid}`,
  UPDATE_AGREEMENT: `${BASE_URL}/Agreement/${endPoints.updateAgreement}`,
  GET_AGREEMENT_BY_CONTACT_ID: `${BASE_URL}/Agreement/${endPoints.getAgreementByContactId}`,
  UPDATE_AGREEMENT_PDF: `${BASE_URL}/Agreement/${endPoints.updatePdf}`,
  DELETE_AGREEMENT: `${BASE_URL}/Agreement/${endPoints.deleteAgreement}`,
  EDIT_AGREEMENT: `${BASE_URL}/Agreement/${endPoints.editAgreement}`,
  GET_DECRYPTED_URL: `${BASE_URL}/Agreement/${endPoints.decrypted}`,
  // Agreement/encrypt

  // files
  GET_ALL_FILES: `${BASE_URL}/files/${endPoints.getAllfiles}`,
  ADD_FILES: `${BASE_URL}/files/${endPoints.addfiles}`,
  DELETE_FILES: `${BASE_URL}/files/${endPoints.deletefiles}`,
  // chat
  GET_ALL_CHATS: `${BASE_URL}/Chat/${endPoints.getAllChats}-chatroomid`,
  GET_CHATROOM_PAGINATION: `${BASE_URL}/Chat/${endPoints.getChatroomPagination}`,
  GET_CHAT_BY_SEARCH: `${BASE_URL}/Chat/${endPoints.getChatBySearch}`,
  UPDATE_CHAT_READ: `${BASE_URL}/Chat/update-chat-read`,
  READ_CHAT_BY_ID: `${BASE_URL}/Chat/${endPoints.readChatById}`,
  ASSIGN_CHAT_TO_CONTACT: `${BASE_URL}/Chat/${endPoints.assignToChatToContact}`,
  UNASSIGN_CHAT_TO_CONTACT: `${BASE_URL}/Chat/${endPoints.unassignToChatToContact}`,
  CHECK_ISLOGIN: `${BASE_URL}/user-login/check-isLogin`,
  SAVE_TOKEN: `${BASE_URL}/user-login/save-token`,

  SEND_MESSAGE_CHAT: `${BASE_URL}/Chat/${endPoints.sendMessageChat}`,
  SEND_MESSAGE_GROUP: `${BASE_URL}/Chat/${endPoints.sendMessageGroup}`,
  GET_ROOM_ID: `${BASE_URL}/Chat/${endPoints.getRoomId}`,
  GET_UNREAD_COUNT: `${BASE_URL}/Chat/${endPoints.getUnreadCount}`,
  CREATE_GROUP: `${BASE_URL}/Chat/${endPoints.createGroup}`,
  EDIT_GROUP: `${BASE_URL}/Chat/${endPoints.editGroup}`,
  FETCH_GROUP_MEMBERS: `${BASE_URL}/Chat/${endPoints.fetchMembers}`,
  DELETE_CHAT_MESSAGE: `${BASE_URL}/Chat/${endPoints.deleteMessage}`,
  DELETE_CHAT_ROOM: `${BASE_URL}/Chat/${endPoints.deleteChatroom}`,
  EDIT_CHAT_MESSAGE: `${BASE_URL}/Chat/${endPoints.editMessage}`,
  CHAT_MESSAGE_JOIN: `${BASE_URL}/Chat/Join`,
  CHAT_MESSAGE_UNJOIN: `${BASE_URL}/Chat/unJoin`,
  PIN_CHAT_MESSAGE: `${BASE_URL}/Chat/${endPoints.pinChat}`,
  UN_PIN_CHAT_MESSAGE: `${BASE_URL}/Chat/${endPoints.unPinChat}`,
  GET_ALL_FILES_BY_ROOM_ID: `${BASE_URL}/Chat/${endPoints.getAllChatFile}`,

  // Attachments
  ADD_FOLDERS: `${BASE_URL}/Attachment/${endPoints.addFolders}`,
  GET_ALL_FOLDER: `${BASE_URL}/Attachment/${endPoints.getAllFolder}`,
  GET_ATTACHMENT_BY_FOLDER_ID: `${BASE_URL}/Attachment/${endPoints.getAttachmentsByUserId}`,
  ADD_ATTACHMENTS: `${BASE_URL}/Attachment/${endPoints.addAttachment}`,
  DELETE_FOLDER: `${BASE_URL}/Attachment/${endPoints.delete}`,
  UPDATE_FOLDER: `${BASE_URL}/Attachment/${endPoints.editFolder}`,
  DELETE_ATTACHMENTTS: `${BASE_URL}/Attachment/${endPoints.deleteAttachment}`,
  EDIT_ATTACHMENT_FILE: `${BASE_URL}/Attachment/${endPoints.editAttachmentFile}`,

  // appointment setter.3

  ADD_AS: `${BASE_URL}/assignappoinment/${endPoints.addAS}`,
  FETCH_UNASSIGNED_AS: `${BASE_URL}/assignappoinment/${endPoints.getAllUnAssignedAS}`,
  ADD_BULK_CUSTOMER: `${BASE_URL}/assignappoinment/${endPoints.addBulkCustomer}`,
  FETCH_GET_ALL_ASSIGN_APPOINMENT: `${BASE_URL}/assignappoinment/${endPoints.getAllAssignAppointment}`,
  FETCH_ALL_CUSTOMERS: `${BASE_URL}/assignappoinment/${endPoints.getAllCustomers}`,
  FETCH_ASSIGN_APPOINMENT_BY_CONTACTID: `${BASE_URL}/assignappoinment/${endPoints.getAllCustomerByContactId}`,
  CUSTOMER_STATUS: `${BASE_URL}/assignappoinment/${endPoints.customerStatus}`,
  CHANGE_SETTER: `${BASE_URL}/assignappoinment/${endPoints.updateSetter}`,
  DELETE_AS_ASSIGN: `${BASE_URL}/assignappoinment/${endPoints.delete}`,
  TOGGLE_AS_STATUS: `${BASE_URL}/assignappoinment/${endPoints.toggleAsEmailSms}`,
  GET_AS_LIST: `${BASE_URL}/assignappoinment/${endPoints.asForAssign}`,

  // dnc
  ADD_DNC: `${BASE_URL}/dnc/${endPoints.add}`,
  ADD_DNC_V1: `${BASE_URL}/dnc/${endPoints.dncAddV1}`,
  GET_ALL_DNC: `${BASE_URL}/dnc/${endPoints.getAll}`,
  GET_ONLINE_USERS: `${BASE_URL}/chat/${endPoints.getOnlineUserList}`,
  GET_ONLINE_AGENTS: `${BASE_URL}/call-campaign/${endPoints.getOnlineAgents}`,
  GET_DNC_ROLEID: `${BASE_URL}/dnc/${endPoints.dncGetRoleId}`,
  GET_DNC_USERID: `${BASE_URL}/dnc/${endPoints.dncGetRoleId}`,
  DELETE_DNC: `${BASE_URL}/dnc/${endPoints.delete}`,
  DELETE_DNC_BY_CONTACT_ID: `${BASE_URL}/dnc/${endPoints.deletebycontactid}`,

  // token
  GENERATE_TOKEN: `${BASE_URL}/Attachment/${endPoints.generateToken}`,
  VALIDATE_TOKEN: `${BASE_URL}/Attachment/${endPoints.validateToken}`,
  // reports
  FETCH_REPORTS_PAGINATION: `${BASE_URL}/Report/${endPoints.getAllReportsByUserId}`,
  SAVE_REPORT: `${BASE_URL}/Report/${endPoints.addReport}`,
  DELETE_REPORT: `${BASE_URL}/Report/${endPoints.delete}`,
  FETCH_ALL_CAMPAIGN_PERFORMANCE: `${BASE_URL}/call-campaign/${endPoints.getAllCallCampaign}`,
  FETCH_ALL_SALES_PERFORMANCE: `${BASE_URL}/Report/${endPoints.getAllSalesPerformance}`,

  // customer

  SEND_PAYMENT_REQUEST: `${BASE_URL}/payment-invoice/${endPoints.sendPayRequest}`,
  SEND_CHECKOUT_PAYMENT_REQUEST: `${BASE_URL}/payment-invoice/${endPoints.submitCheckoutPayment}`,
  BONUS_PAYMENT_REQUEST: `${BASE_URL}/payment/${endPoints.bonusPayRequest}`,
  FETCH_INVOICE_SUMMARY: `${BASE_URL}/payment/${endPoints.getInvoiceSummary}`,
  UPLOAD_INVOICE: `${BASE_URL}/payment/${endPoints.uploadInvoice}`,
  GET_PAYMENT_LOG: `${BASE_URL}/payment-invoice/${endPoints.getPaymentLogs}`,

  // payment
  GET_ALL_PAYMENT_HISTORY: `${BASE_URL}/payment/${endPoints.getAll}`,
  UPDATE_PAYMENT_LOG: `${BASE_URL}/payment/update-contactId`,
  RESEND_PAYMENT: `${BASE_URL}/Agreement/${endPoints.resendPayment}`, // use this change endPoint

  // payment invoice
  ADD_PAYMENT_INVOICE: `${BASE_URL}/payment-invoice/${endPoints.add}`,
  EDIT_PAYMENT_INVOICE: `${BASE_URL}/payment-invoice/${endPoints.edit}`,
  ADD_PAYMENT_ITEM: `${BASE_URL}/payment-invoice/${endPoints.addPaymentItem}`,
  GET_PAYMENT_INVOICE: `${BASE_URL}/payment-invoice/${endPoints.getAll}`,
  GET_PAYMENT_INVOICE_BY_ID: `${BASE_URL}/payment-invoice/${endPoints.getbyid}`,
  GET_PAYMENT_DETAILS_BY_ID: `${BASE_URL}/payment-invoice/${endPoints.getpayment}`,
  GET_CHECKOUT_INVOICE_BY_ID: `${BASE_URL}/payment-invoice/${endPoints.getCheckoutLinkInvoice}`,
  GET_CARD_DATA: `${BASE_URL}/payment-invoice/${endPoints.getCardFee}`,
  FETCH_CARD_ON_FILE: `${BASE_URL}/payment-invoice/${endPoints.getSavedCards}`,
  ADD_PAYMENT_REMINDER: `${BASE_URL}/payment-invoice/send-payment-reminder`,
  FETCH_PAYMENT_REMINDER: `${BASE_URL}/payment-invoice/get-payment-reminders`,
  DELETE_INVOICE_BY_ID: `${BASE_URL}/payment-invoice/${endPoints.deleteInvoice}`,

  //  Campaign
  ADD_CAMPAIGN: `${BASE_URL}/campaign/${endPoints.add}`,
  DELETE_CAMPAIGN: `${BASE_URL}/campaign/${endPoints.delete}`,
  FETCH_ALL_CAMPAIGN: `${BASE_URL}/campaign/${endPoints.getAll}`,
  FETCH_CAMPAIGN_BY_CONTACTID: `${BASE_URL}/campaign/${endPoints.getByContactId}`,

  // quality control
  FETCH_ALL_QUALITY: `${BASE_URL}/quality/${endPoints.getAll}`,
  VIDEO_VOICE_ADD: `${BASE_URL}/${endPoints.videoVoiceAdd}`,
  VIDEO_VOICE_EMAIL: `${BASE_URL}/${endPoints.videoVoiceEmail}`,
  VIDEO_LIBRARY: `${BASE_URL}/video-voice/${endPoints.getallLibrary}`,
  ADD_VIDEO_VOICE_TEMPLATE: `${BASE_URL}/video-voice/${endPoints.addVideoVoiceTemplate}`,
  UPDATE_VIDEO_VOICE_TEMPLATE: `${BASE_URL}/video-voice/${endPoints.updateVideoVoiceTemplate}`,

  // sequence
  ADD_SEQUENCE: `${BASE_URL}/sequence/${endPoints.add}`,
  DELETE_SEQUENCE: `${BASE_URL}/sequence/${endPoints.delete}`,
  DELETE_SEQUENCE_CONTACT: `${BASE_URL}/sequence/${endPoints.deleteContact}`,
  UPDATE_SEQUENCE: `${BASE_URL}/sequence/${endPoints.edit}`,
  UPDATE_SEQUENCE_Template: `${BASE_URL}/sequence/${endPoints.editTemplate}`,
  FETCH_SEQUENCE: `${BASE_URL}/sequence/${endPoints.getAllPagination}`,
  GETSEQUENCEBYID: `${BASE_URL}/sequence/${endPoints.getById}`,
  GETSEQUENCECONTACTSBYID: `${BASE_URL}/sequence/${endPoints.getAllContactsSequenceById}`,
  ADD_SEQUENCE_TEMPLATE: `${BASE_URL}/sequence/${endPoints.addSequenceTemplate}`,
  TEST_SEQUENCE_EMAIL: `${BASE_URL}/sequence/${endPoints.testEmail}`,
  ACTIVE_DEACTIVE_SEQUENCE: `${BASE_URL}/sequence/${endPoints.activeDeactive}`,
  ADD_SEQUENCE_CONTACT: `${BASE_URL}/sequence/${endPoints.addSequenceContact}`,
  GETALLDAYSBYSEQID: `${BASE_URL}/sequence/${endPoints.getAllDaysBySeqId}`,
  CLONE_SEQUENCE: `${BASE_URL}/sequence/${endPoints.cloneSequence}`,
  DELETE_SEQUENCE_TEMPLATE: `${BASE_URL}/sequence/${endPoints.deleteSequenceTemplate}`,

  // holiday
  ADD_HOLIDAY: `${BASE_URL}/holiday/${endPoints.add}`,
  DELETE_HOLIDAY: `${BASE_URL}/holiday/${endPoints.delete}`,
  UPDATE_HOLIDAY: `${BASE_URL}/holiday/${endPoints.edit}`,
  FETCH_HOLIDAY: `${BASE_URL}/holiday/${endPoints.getAll}`,

  // company
  ADD_COMPANY: `${BASE_URL}/company/add-company`,
  EDIT_COMPANY: `${BASE_URL}/company/edit-company`,
  REMOVE_SIGNALR: `${BASE_URL}/user-login/remove-signalr`,

  CHANGE_DEFAULT_COMPANY: `${BASE_URL}/user-login/${endPoints.changeDefaultCompany}`,
  DELETE_COMPANY: `${BASE_URL}/company/${endPoints.delete}`,
  FETCH_ALL_COMPANY: `${BASE_URL}/company/${endPoints.getAll}`,
  FETCH_COMPANY_BY_USER_ID: `${BASE_URL}/company/${endPoints.getAllByUserId}`,
  FETCH_COMPANY_BY_ID: `${BASE_URL}/company/${endPoints.getbyid}`,
  // lead group
  ADD_LEAD_GROUP: `${BASE_URL}/LeadSourceGroup/${endPoints.add}`,
  UPDATE_LEAD_GROUP: `${BASE_URL}/LeadSourceGroup/${endPoints.edit}`,
  DELETE_LEAD_GROUP: `${BASE_URL}/LeadSourceGroup/${endPoints.delete}`,
  FETCH_ALL_LEAD_GROUP: `${BASE_URL}/LeadSourceGroup/${endPoints.getAll}`,
  FETCH_LEAD_GROUP_BY_USER_ID: `${BASE_URL}/LeadSourceGroup/${endPoints.getById}`,

  // dripCampaign
  ADD_DRIPCAMPAIGN: `${BASE_URL}/dripCampaign/${endPoints.add}`,
  ADD_DRIPCAMPAIGN_CONTACT: `${BASE_URL}/dripCampaign/${endPoints.addDripCampaignContact}`,
  ADD_DRIPCAMPAIGN_LEAD_GROUP: `${BASE_URL}/dripCampaign/${endPoints.addDripLeadSourceGroup}`,
  UPDATE_DRIPCAMPAIGN: `${BASE_URL}/dripCampaign/${endPoints.edit}`,
  DELETE_DRIPCAMPAIGN: `${BASE_URL}/dripCampaign/${endPoints.delete}`,
  DELETE_DRIPCAMPAIGN_LEAD_GROUP: `${BASE_URL}/dripCampaign/${endPoints.deleteDripLeadSourceGroup}`,
  FETCH_ALL_DRIPCAMPAIGN_PAGINATION: `${BASE_URL}/dripCampaign/${endPoints.getAllPagination}`,
  FETCH_ALL_DRIPCAMPAIGN: `${BASE_URL}/dripCampaign/${endPoints.getAll}`,
  FETCH_DRIPCAMPAIGN_BY_ID: `${BASE_URL}/dripCampaign/${endPoints.getById}`,
  FETCH_LEAD_GROUP_BY_CAMPAIGN_ID: `${BASE_URL}/dripCampaign/${endPoints.getallDripLeadSourceGroupByDripCampaignId}`,
  FETCH_CONTACT_BY_CAMPAIGN_ID: `${BASE_URL}/dripCampaign/${endPoints.getallContactPaginationByDripCampaignId}`,
  COPY_DRIPCAMPAIGN: `${BASE_URL}/dripCampaign/${endPoints.copycampaign}`,
  DRIPCAMPAIGN_ACTIVE_DEACTIVE: `${BASE_URL}/dripCampaign/${endPoints.activeDeactive}`,
  DRIPCAMPAIGN_STEP_SENT_COUNT: `${BASE_URL}/dripCampaign/${endPoints.stepSentCunt}`,
  DRIPCAMPAIGN_CONTACT_STEP_SENT: `${BASE_URL}/dripCampaign/${endPoints.contactStepSent}`,

  // dripCampaign folder
  ADD_DRIPCAMPAIGN_FOLDER: `${BASE_URL}/dripCampaign/${endPoints.addCampaignFolder}`,
  ASSIGN_DRIPCAMPAIGN_TO_FOLDER: `${BASE_URL}/dripCampaign/${endPoints.assignCampaignToFolder}`,
  UPDATE_DRIPCAMPAIGN_FOLDER: `${BASE_URL}/dripCampaign/${endPoints.editCampaignFolder}`,
  DELETE_DRIPCAMPAIGN_FOLDER: `${BASE_URL}/dripCampaign/${endPoints.deleteCampaignFolder}`,
  FETCH_ALL_DRIPCAMPAIGN_FOLDER: `${BASE_URL}/dripCampaign/${endPoints.getallCampaignFolder}`,

  // dripCampaignStep
  ADD_DRIPCAMPAIGNSTEP: `${BASE_URL}/dripCampaignStep/${endPoints.add}`,
  UPDATE_DRIPCAMPAIGNSTEP: `${BASE_URL}/dripCampaignStep/${endPoints.edit}`,
  DELETE_DRIPCAMPAIGNSTEP: `${BASE_URL}/dripCampaignStep/${endPoints.delete}`,
  FETCH_ALL_DRIPCAMPAIGNSTEP: `${BASE_URL}/dripCampaignStep/${endPoints.getAll}`,
  ADD_CAMPAIGN_IN_FOLDER: `${BASE_URL}/dripCampaign/${endPoints.addCampaignInFolder}`,
  ADD_POSTCARD_STEP: `${BASE_URL}/dripCampaignStep/${endPoints.addPostCard}`,
  ADD_LETTER_STEP: `${BASE_URL}/dripCampaignStep/${endPoints.addLetter}`,
  // call manager
  CALLER_IDS: `${ORIGIN}/${endPoints.callerIds}`,
  ADD_CALLER_ID: `${ORIGIN}/${endPoints.addCallerId}`,
  DELETE_CALLER_ID: `${ORIGIN}/${endPoints.deleteCallerId}`,
  DISABLE_ENABLE_CALLER_ID: `${ORIGIN}/${endPoints.disableEnable}`,
  CALL_LOG_LIST: `${BASE_URL}/call-manager/${endPoints.callLogList}`,
  UPDATE_CALL_SID: `${BASE_URL}/call-manager/${endPoints.updateCallSid}`,
  UPDATE_INCOMINGCALL_BY_CALL_SID: `${BASE_URL}/call-manager/${endPoints.updateIncomingCallByCallSid}`,
  UPDATE_DISPOSITION_SID: `${BASE_URL}/call-manager/${endPoints.UpdateDispositionSid}`,
  UPDATE_CALL_NOTES_BY_ID: `${BASE_URL}/call-logs/${endPoints.updateCallNote}`,
  CALL_NOTE: `${BASE_URL}/call-manager/${endPoints.callNote}`,
  HOLD_UNHOLD: `${BASE_URL}/call-manager/${endPoints.holdUnhold}`,
  CALL_END: `${BASE_URL}/call-manager/${endPoints.callEnd}`,
  REMOVE_CONFERENCE: `${BASE_URL}/call-manager/${endPoints.removeConference}`,
  FETCH_ALL_STATUS_REPORTS: `${BASE_URL}/call-manager/${endPoints.getAllAsTimeStatus}`,
  FETCH_ALL_AGENT_REPORT: `${BASE_URL}/call-manager/${endPoints.getAllAsAgent}`,
  BLIND_TRANSFER: `${BASE_URL}/call-manager/${endPoints.blindTransfer}`,
  START_CALL_WRAP: `${BASE_URL}/call-manager/${endPoints.startCallWrap}`,
  ADD_CALL_SCHEDULE: `${BASE_URL}/call-campaign/${endPoints.addCallSchedule}`,
  FETCH_ALL_CALL_SCHEDULE: `${BASE_URL}/call-campaign/${endPoints.addCallSchedule}`, // need to change end point
  FETCH_ALL_CALL_BACK_LIST: `${BASE_URL}/call-campaign/${endPoints.getScheduledCalls}`,
  TOGGLE_CONTACT_STATUS: `${BASE_URL}/contact/${endPoints.toggleContactStatus}`,
  GET_DISPOSITION_CODES: `${BASE_URL}/disposition-status/${endPoints.getallDispositionOrderby}`,

  // virutal number
  SEARCH_VIRTUAL_NUMBER: `${BASE_URL}/virtual-number/${endPoints.searchVirtualNumber}`,
  BUY_VIRTUAL_NUMBER: `${BASE_URL}/virtual-number/${endPoints.buyVirtualNumber}`,
  ASSIGN_NUMBER_USER: `${BASE_URL}/virtual-number/${endPoints.assignNumberToUser}`,
  UNASSIGN_NUMBER_USER: `${BASE_URL}/virtual-number/${endPoints.unAssignNumberToUser}`,
  UNASSIGN_NUMBER_CLIENT: `${BASE_URL}/virtual-number/${endPoints.unAssignNumberToClient}`,
  GETALL_VIRTUAL_NUMBER: `${BASE_URL}/virtual-number/${endPoints.getAllVirtualNumber}`,
  GETALL_VIRTUAL_NUMBER_AS_CALLER_ID: `${BASE_URL}/virtual-number/${endPoints.getAllVirtualNumberAsCIDs}`,
  GETALL_VIRTUAL_NUMBER_BY_COMP_ID: `${BASE_URL}/virtual-number/${endPoints.getAllVirtualNumberByCompId}`,
  DELETE_VIRTUAL_NUMBER: `${BASE_URL}/virtual-number/${endPoints.removeVirtualNumber}`,
  UPDATE_FORWARD_NUMBER: `${BASE_URL}/virtual-number/update-forward-number`,
  // call result
  ADD_CALL_RESULT: `${BASE_URL}/call-result/${endPoints.add}`,
  UPDATE_CALL_RESULT: `${BASE_URL}/call-result/${endPoints.edit}`,
  UPDATE_CALL_RESULT_SETTING: `${BASE_URL}/call-result/${endPoints.updateCallResultSetting}`,
  DELETE_CALL_RESULT: `${BASE_URL}/call-result/${endPoints.delete}`,
  FETCH_ALL_CALL_RESULT: `${BASE_URL}/call-result/${endPoints.getAll}`,

  // DISPOSITION
  ADD_DISPOSITION: `${BASE_URL}/disposition-status/${endPoints.add}`,
  FETCH_ALL_DISPOSITION: `${BASE_URL}/disposition-status/${endPoints.getAll}`,

  // POSTCARD
  ADD_POSTCARD: `${BASE_URL}/postcard/${endPoints.add}`,
  UPDATE_POSTCARD: `${BASE_URL}/postcard/${endPoints.edit}`,
  GET_ALL_POSTCARD: `${BASE_URL}/postcard/${endPoints.getAll}`,
  DELETE_POSTCARD: `${BASE_URL}/postcard/${endPoints.getAll}`,

  // POSTCARD TEMPLATE

  ADD_POSTCARD_TEMPLATE: `${BASE_URL}/post-card-template/${endPoints.add}`,
  UPDATE_POSTCARD_TEMPLATE: `${BASE_URL}/post-card-template/${endPoints.edit}`,
  GET_ALL_POSTCARD_TEMPLATE: `${BASE_URL}/post-card-template/${endPoints.getAll}`,
  GET_ALL_POSTCARD_TYPES: `${BASE_URL}/post-card-template/${endPoints.getPostcardTypes}`,
  DELETE_POSTCARD_TEMPLATE: `${BASE_URL}/post-card-template/${endPoints.getAll}`,

  // LETTER
  ADD_LETTER: `${BASE_URL}/letter/${endPoints.add}`,
  UPDATE_LETTER: `${BASE_URL}/letter/${endPoints.edit}`,
  GET_ALL_LETTER: `${BASE_URL}/letter/${endPoints.getAll}`,
  DELETE_LETTER: `${BASE_URL}/letter/${endPoints.getAll}`,

  // LETTER TEMPLATE
  ADD_LETTER_TEMPLATE: `${BASE_URL}/letterTemplate/${endPoints.add}`,
  UPDATE_LETTER_TEMPLATE: `${BASE_URL}/letterTemplate/${endPoints.edit}`,
  GET_ALL_LETTER_TEMPLATE: `${BASE_URL}/letterTemplate/${endPoints.getAll}`,
  DELETE_LETTER_TEMPLATE: `${BASE_URL}/letterTemplate/${endPoints.getAll}`,

  // Call Campaing
  ADD_CALL_CAMPAIGN: `${BASE_URL}/call-campaign/${endPoints.add}`,
  EDIT_CALL_CAMPAIGN: `${BASE_URL}/call-campaign/${endPoints.edit}`,
  GET_ALL_CALL_CAMPAIGN: `${BASE_URL}/call-campaign/${endPoints.getAll}`,
  GET_TIME_SLOTS: `${BASE_URL}/call-campaign-time-slots/${endPoints.getAll}`,
  DELETE_DISPOSITION: `${BASE_URL}/disposition-status/${endPoints.delete}`,
  UPDATE_DISPOSITION_SETTING: `${BASE_URL}/disposition-status/${endPoints.edit}`,
  GET_CALL_INFO: `${BASE_URL}/call-manager/${endPoints.getCallInfo}`,
  GET_NEXT_NUMBER: `${BASE_URL}/call-campaign/get-last-contact`,
  REMOVE_NUMBER: `${BASE_URL}/call-campaign/update-calling-status`,
  GET_MAX_CALL: `${BASE_URL}/call-campaign/${endPoints.getMaxCall}`,
  GET_MAX_CALL_CONTACT: `${BASE_URL}/contact/${endPoints.getMaxCallContact}`,
  DELETE_CALL_CAMPAIGN_SCRIPT: `${BASE_URL}/delete-campaign-script/${endPoints.delete}`,
  DELETE_IMPORT_HISTORY: `${BASE_URL}/importedcustomerfiless/${endPoints.importHistoryUndo}`,
  DELETE_CALL_CAMPAIGN: `${BASE_URL}/call-campaign/delete-call-campaign`,
  ADD_CALL_CAMPAIGN_SCRIPT: `${BASE_URL}/add-call-campaign-script/${endPoints.add}`,

  // CampaignTimeSlot
  ADD_CAMPAIGN_TIME_SLOT: `${BASE_URL}/call-campaign-time-slots/${endPoints.add}`,
  FETCH_ALL_CAMPAIGN_TIME_SLOT: `${BASE_URL}/call-campaign-time-slots/${endPoints.getAll}`,
  UPDATE_CAMPAIGN_TIME_SLOT_SETTING: `${BASE_URL}/call-campaign-time-slots/${endPoints.edit}`,
  GET_CAMPAIGN_DETAILS: `${BASE_URL}/call-campaign/get-customers-by-campaigid`,
  GET_CUSTOMER_BY_ID: `${BASE_URL}/call-campaign/get-customers-by-id`,
  GET_CUSTOMER_POSITION: `${BASE_URL}/call-campaign/get-next-prev`,
  DELETE_CAMPAIGN_TIME_SLOT: `${BASE_URL}/call-campaign-time-slots/${endPoints.delete}`,
  GET_CALL_CAMPAIGN_SCHEDULE: `${BASE_URL}/call-campaign/get-scheduled-calls`,
  GET_CALL_CAMPAIGN_SCHEDULE_COUNT: `${BASE_URL}/call-campaign/get-scheduled-calls-count`,
  GET_CALLER_IDS: `${ORIGIN}/get-caller-ids-for-call-campaign`,
  GET_COMPANY_DETAILS_BY_CONFID: `${ORIGIN}/getall-company-by-confid`,
  EIDT_CUSTOMER_DETAILS: `${BASE_URL}/call-campaign/update-customer`,
  LOGOUT_AGENT: `${BASE_URL}/user-login/${endPoints.agentLogout}`,
  GET_USER_COMMUNICATION_ACCESS: `${BASE_URL}/user-login/${endPoints.getUserCommunicationAccess}`,
  FETCH_ALL_COMPANY_EMAIL_DROP_DOWN: `${BASE_URL}/user-login/${endPoints.getcompaniesbyemail}`,
  GET_CLIENT_COMMUNICATION_ACCESS: `${BASE_URL}/user-login/${endPoints.getUserCommunicationAccess}`,
  LOGOUT_SUPER_ADMIN: `${BASE_URL}/user-login/${endPoints.superAdminLogout}`,
  LOGOUT_CLIENT: `${BASE_URL}/user-login/${endPoints.clientLogout}`,

  CALL_HANGUP_AGENT: `${BASE_URL}/call-manager/${endPoints.callHangupAgent}`,

  REQUEST_PERMISSION: `${BASE_URL}/call-logs/${endPoints.requestPermission}`,
  FETCH_TIME_LINE: `${BASE_URL}/assignappoinment/${endPoints.timeLine}`,
  GET_MISSED_CALLS: `${BASE_URL}/call-logs/${endPoints.getallmissedcalls}`,
  DISMISS_MISSED_CALL_ALL: `${BASE_URL}/call-logs/${endPoints.missedCallDismissAll}`,
  DISMISS_All_MISSED_CALL: `${BASE_URL}/call-logs/${endPoints.dismissAllMissedCalls}`,

  // Dashboard
  FETCH_DEAL_CARD: `${BASE_URL}/dashboard/${endPoints.getDashboardCountAgentPerformance}`,
  FETCH_DEAL_COUNT: `${BASE_URL}/dashboard/${endPoints.getDashboardDealCount}`,
  FETCH_DASHBOARD_MAIN_CARD: `${BASE_URL}/dashboard/${endPoints.getDashboardMainCard}`,
  FETCH_DASHBOARD_COUNT: `${BASE_URL}/dashboard/${endPoints.getDashboardCount}`,
  FETCH_DASHBOARD_COUNT_AGENT_PERFORMANCE: `${BASE_URL}/dashboard/${endPoints.getDashboardCountAgentPerformance}`,
  FETCH_COMUNICATION: `${BASE_URL}/dashboard/${endPoints.getComunication}`,

  // Forward NUmber
  ADD_FORWARD_NUMBER: `${BASE_URL}/call-forward/${endPoints.add}`,
  FETCH_ALL_FORWARD_NUMBER: `${BASE_URL}/call-forward/${endPoints.getAll}`,
  DELETE_FORWARD_NUMBER: `${BASE_URL}/call-forward/${endPoints.delete}`,
  FETCH_ALL_GLOBAL_SEARCH: `${BASE_URL}/dashboard/${endPoints.globalSearch}`,
  FETCH_ALL_GLOBAL_SEARCH_BY_TYPE: `${BASE_URL}/dashboard/${endPoints.globalSearchByType}`,
  FETCH_ALL_GLOBAL_SEARCH_LIST: `${BASE_URL}/dashboard/${endPoints.globalSearchList}`,
  GET_AGENT_CALL_BY_SID: `${BASE_URL}/dashboard/${endPoints.getAgentCallBySid}`,

  // Tigger
  FETCH_ALL_TRIGGER: `${BASE_URL}/triggers/${endPoints.getAll}`,
  ADD_TRIGGER: `${BASE_URL}/triggers/${endPoints.add}`,
  GET_LIVE_CALL_SUGGESTIONS: `${BASE_URL}/triggers/get-recommendations`,
  GET_TIMESLOTES: `${BASE_URL}/call-campaign-time-slots/${endPoints.getAll}`,
  GET_TRANSCRIPTION: `${BASE_URL}/call-transcript/${endPoints.getAllByConfId}`,

  // transcription
  ADD_CALL_TRANSCRIPTION: `${BASE_URL}/call-transcript/add`,
  UPDATE_TRIGGER: `${BASE_URL}/triggers/${endPoints.edit}`,
  DELETE_TRIGGER: `${BASE_URL}/triggers/${endPoints.delete}`,

  // User Specific Permissions
  GET_USER_SPECIFIC_PERMISSIONS: `${BASE_URL}/permission/get-user-specific-permissions`,
  GET_USER_ALREADY_HAVE_PERMISSIONS: `${BASE_URL}/permission/get-user-permissions`,
  GET_CLIENT_ALREADY_HAVE_PERMISSIONS: `${BASE_URL}/permission/get-client-permissions`,
  UPDATE_USER_PERMISSIONS: `${BASE_URL}/permission/client-permissions`,
  GET_USER_SPECIFIC_PERMISSIONS_BY_USER_ID: `${BASE_URL}/permission/get-user-client-permissions`,
  GET_COMMON_PERMISSIONS_USER: `${BASE_URL}/permission/get-common-permissions`,
  GET_ALL_CONVERSATION: `${BASE_URL}/conversation/get-all`,
  GET_ALL_LOGS: `${BASE_URL}/conversation/get-contact-logs`,
  UPDATE_READ_UNREAD: `${BASE_URL}/conversation/read-unread`,

  // Payment Method
  FETCH_PAYMENT_METHOD: `${BASE_URL}/payment-method/${endPoints.getAll}`,
  ADD_PAYMENT: `${BASE_URL}/payment-method/${endPoints.add}`,
  UPDATE_PAYMENT: `${BASE_URL}/payment-method/${endPoints.edit}`,
  DELETE_PAYMENT: `${BASE_URL}/payment-method/${endPoints.delete}`,
  PAYMENT_METHOD_sTATUS: `${BASE_URL}/payment-method/${endPoints.activeDeactive}`,

  // Payment Product

  FETCH_PAYMENT_PRODUCT: `${BASE_URL}/payment-invoice-item/${endPoints.getAll}`,
  ADD_PAYMENT_PRODUCT: `${BASE_URL}/payment-invoice-item/${endPoints.add}`,
  UPDATE_PAYMENT_PRODUCT: `${BASE_URL}/payment-invoice-item/${endPoints.edit}`,
  DELETE_PAYMENT_PRODUCT: `${BASE_URL}/payment-invoice-item/${endPoints.delete}`,
  PAYMENT_INVOICE_STATUS: `${BASE_URL}/payment-invoice-item/${endPoints.activeDeactive}`,

  // Profile (user)edit
  UPDATE_PROFILE: `${BASE_URL}/user/edit-user`,

  // Additional Contact
  ADD_ADDITIONAL_CONTACT: `${BASE_URL}/contactadditionalinformation/${endPoints.add}`,
  EDIT_ADDITIONAL_CONTACT: `${BASE_URL}/contactadditionalinformation/${endPoints.edit}`,
  GET_ADDITIONAL_CONTACT: `${BASE_URL}/contactadditionalinformation/${endPoints.getAll}`,
  GETDETAIL_ADDITIONAL_CONTACT: `${BASE_URL}/contactadditionalinformation/${endPoints.getbyid}`,
  DELETE_ADDITIONAL_CONTACT: `${BASE_URL}/contactadditionalinformation/${endPoints.delete}`,

  // Payment Gateway

  FETCH_PAYMENT_GATEWAY: `${BASE_URL}/payment-gateway/${endPoints.getByCompanyId}`,
  ADD_PAYMENT_GATEWAY: `${BASE_URL}/payment-gateway/${endPoints.add}`,
  UPDATE_PAYMENT_GATEWAY: `${BASE_URL}/payment-gateway/${endPoints.edit}`,
  DELETE_PAYMENT_GATEWAY: `${BASE_URL}/payment-gateway/${endPoints.disable}`,
  DEFAULT_PAYMENT_GATEWAY: `${BASE_URL}/payment-gateway/${endPoints.setDefault}`,
  PAYMENT_GATEWAY_STATUS: `${BASE_URL}/payment-gateway/${endPoints.activeDeactive}`,

  // Payment checkout
  ADD_CHECKOUT_LINK: `${BASE_URL}/checkoutlink/${endPoints.add}`,
  UPDATE_CHECKOUT_LINK: `${BASE_URL}/checkoutlink/${endPoints.edit}`,
  GET_ALL_CHECKOUT_LINK: `${BASE_URL}/checkoutlink/${endPoints.getAll}`,
  DELETE_CHECKOUT_LINK: `${BASE_URL}/checkoutlink/${endPoints.delete}`,
  STATUS_CHECKOUT_LINK: `${BASE_URL}/checkoutlink/${endPoints.getActiveDeactive}`,
  ADD_TERMS_CONDITION: `${BASE_URL}/checkoutlink/${endPoints.addTermsAndConditions}`,

  // Payment Setting
  ADD_PAYMENT_SETTING: `${BASE_URL}/invoicesetting/${endPoints.add}`,
  UPDATE_PAYMENT_SETTING: `${BASE_URL}/invoicesetting/${endPoints.edit}`,
  GET_PAYMENT_SETTING: `${BASE_URL}/invoicesetting/${endPoints.getInvoiceSetting}`,

  // Black List

  ADD_NUMBER_TO_BLACK_LIST: `${BASE_URL}/black-list-number/${endPoints.add}`,
  GET_ALL_BLACK_LIST: `${BASE_URL}/black-list-number/${endPoints.getAll}`,
  DELETE_BLACK_LIST: `${BASE_URL}/black-list-number/${endPoints.delete}`,

  // Approved Customer
  GET_APPROVED_CUSTOMER: `${BASE_URL}/call-campaign/${endPoints.getApprovedCustomer}`,

  // Power Dialer
  ADD_POWER_DIALER_SETTING: `${BASE_URL}/power-dialer/${endPoints.addSettings}`,
  GET_POWER_DIAL_SETTING: `${BASE_URL}/power-dialer/${endPoints.getPowerDialSetting}`,

  // Move To Other Company
  MOVE_TO_OTHER_COMPANY: `${BASE_URL}/contact/move-to-other-company`,

  // Calling Script
  ADD_CALLING_SCRIPT: `${BASE_URL}/call-script/${endPoints.getCallingScript}`,
  FETCH_ALL_CALLING_SCRIPT: `${BASE_URL}/call-script/${endPoints.getAll}`,
  DELETE_CALLING_SCRIPT: `${BASE_URL}/call-script/${endPoints.delete}`,
  EDIT_CALLING_SCRIPT: `${BASE_URL}/call-script/${endPoints.edit}`,

  // Email Configuration
  ADD_EMAIL_CONFIGURATION: `${BASE_URL}/userplatformemail/${endPoints.add}`,
  EDIT_EMAIL_CONFIGURATION: `${BASE_URL}/userplatformemail/${endPoints.edit}`,
  GET_EMAIL_CONVERSATION: `${BASE_URL}/userplatformemail/${endPoints.conversationEmailV1}`,
  FETCH_ALL_EMAIL_CONFIGURATION: `${BASE_URL}/userplatformemail/${endPoints.getAll}`,
  FETCH_ALL_SIGNATURE_DROP_DOWN: `${BASE_URL}/userplatformemail/${endPoints.signatureDropDown}`,
  DELETE_EMAIL_CONFIGURATION: `${BASE_URL}/userplatformemail/${endPoints.delete}`,

  // user email
  USER_EMAIL_ASSIGN: `${BASE_URL}/user-email/${endPoints.add}`,
  GET_USER_EMAIL: `${BASE_URL}/user-email/${endPoints.getAll}`,
  USER_EMAIL_SET_DEFAULT: `${BASE_URL}/user-email/${endPoints.setDefault}`,
  ADD_USER_EMAIL_ASSIGN_SIGNATURE: `${BASE_URL}/user-email/${endPoints.assignSignature}`,
  DELETE_USER_SIGNATURE_BY_ID: `${BASE_URL}/user-email/${endPoints.deleteSignature}`,

  // CallCampaignCustomer
  CALL_CAMPAIGN_CUSTOMER: `${BASE_URL}/CallCampaignCustomer/${endPoints.add}`,
  DELETE_CALL_CAMPAIGN_CUSTOMER: `${BASE_URL}/CallCampaignCustomer/${endPoints.delete}`,
  COMPANY_CHANGE: `${BASE_URL}/user-login/${endPoints.companyChange}`,

  // COMMON SERVUICE
  GENERATE_TRANSCRIPTION: "https://eecalls.com/api/audio-to-text",

  // contact form

  GET_ALL_USER_FORM_BY_CONTACT_ID: `${BASE_URL}/ContactForm/${endPoints.getAllContactForm}`,
  ADD_USER_FORM: `${BASE_URL}/ContactForm/${endPoints.addContactForm}`,
  GET_USER_FORM_BY_URL: `${BASE_URL}/ContactForm/${endPoints.getContactFormByUrl}`,
  SUBMIT_USER_FORM: `${BASE_URL}/ContactForm/${endPoints.submitContactForm}`,
  DELETE_USER_FORM: `${BASE_URL}/ContactForm/${endPoints.deleteContactForm}`,
};

export default API_URLS;
