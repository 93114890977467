import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import { IDuplicateResponse } from "pages/admin/settings/screens/reconciliation/query/useFetchDuplicateContactByUserId.ts";
import { IContactsResponse } from "pages/user/contacts/query/useFetchContacts";
import { IAddiotionalContactRequestRoot } from "pages/user/contacts/types/IAddiotionalContactRequestRoot";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export interface SearchContactRoot {
  statusCode: number;
  data: SearchContactData[];
}

export interface SearchContactData {
  id: number;
  index: number;
  userId: number;
  firstName: string;
  lastName: string;
  phone: string;
  alternatePhone: string;
  mobile: string;
  email: string;
  secondEmail: string;
  pipelineId: number;
  pipelineName: string;
  leadSourceId: number;
  leadSourceName: string;
  leadStatusId: number;
  leadStatusName: string;
  referredBy: number;
  entityTypeId: string;
  recordOwnerId: number;
  recordOwnerName: any;
  followUpId: number;
  followUpEmail: string;
  dateContractSent: any;
  taxId: number;
  seqStartDate: any;
  salesRepId: number;
  companyName: string;
  companyWebsite: string;
  roleId: string;
  status: number;
  createdOn: string;
  createdBy: string;
  updatedOn?: string;
  updatedBy?: string;
  address: any;
  card: any;
  contractDetails: any;
  tagDetail: any;
}

async function saveContact(body: any): Promise<IResponse> {
  return body?.id
    ? client.put(API_URLS.EDIT_CONTACT, body)
    : client.post(API_URLS.ADD_CONTACT, body);
}

async function removeContact(userId: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_CONTACT}/${userId}`);
}

async function editContact(values: any): Promise<IResponse> {
  return client.put(`${API_URLS.EDIT_CONTACT}`, values);
}

async function editContract(values: any): Promise<IResponse> {
  // return client.put(`${API_URLS.EDIT_CONTRACT}`, values);
  return values?.id
    ? client.put(`${API_URLS.EDIT_CONTRACT}`, values)
    : client.post(`${API_URLS.ADD_CONTRACT}`, values);
}
async function saveAddress(values: any): Promise<IResponse> {
  return values?.id
    ? client.put(`${API_URLS.EDIT_ADDRESS}`, values)
    : client.post(`${API_URLS.ADD_ADDRESS}`, values);
}

async function savePreference(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_PREFERENCE, body);
}

async function fetchContactsSearch(
  query: string,
  isClient = false,
): Promise<IResponse> {
  let url = `${API_URLS.SEARCH_CONTACT}?${query}`;

  if (isClient) {
    url += `&isClient=${isClient}`;
  }
  return client.get(url);
}

async function getPreference(userId: any): Promise<IResponse> {
  return client.get(`${API_URLS.GET_PREFERENCE}?UserId=${userId}`);
}

async function fetchContactById(
  id: number,
  urlParams: string,
): Promise<IResponse> {
  let url = `${API_URLS.GET_CONTACT_BY_ID}?id=${id}`;
  if (urlParams) {
    url += decodeURIComponent(urlParams);
  }

  return client.get(url);
}
async function fetchDuplicateContact(id: number): Promise<IDuplicateResponse> {
  return client.get(`${API_URLS.GET_ALL_DUPLICATE}?userId=${id}`);
}

async function deleteBulkContact(data: any): Promise<IResponse> {
  return client.patch(`${API_URLS.BULK_DELETE}`, data);
}
async function bulkImportContact(data: any): Promise<string> {
  return client.post(`${API_URLS.BULK_IMPORT}`, data);
}

async function toggleInvoice(id: number): Promise<IResponse> {
  return client.post(`${API_URLS.TOGGLE_INVOICE}?id=${id}`);

  // const url = `${API_URLS.TOGGLE_INVOICE}`;
  // return client.post(url, body);
}

async function changeLeadOwnerByContactId(body: any): Promise<IResponse> {
  let url = `${API_URLS.UPDATE_LEAD_OWNER}`;
  if (body.url) {
    const endPoint = body.url.replace(/^./, "?");
    url += endPoint;
  }
  return client.put(url, body);
}

async function assignLeadOwner(body: any): Promise<IResponse> {
  let url = `${API_URLS.ASSIGN_LEAD_OWNER}`;
  if (body.url) {
    const endPoint = body.url.replace(/^./, "?");
    url += endPoint;
  }
  return client.post(url, body);
}

async function removeLeadOwnerById(
  contactId: number,
  leadOwnerId: number,
): Promise<IResponse> {
  const url = `${API_URLS.REMOVE_LEAD_OWNER_BY_ID}?contactId=${contactId}&leadOwnerId=${leadOwnerId}`;
  return client.post(url);
}

async function changeLeadSourceByContactId(
  leadSourceId: number,
  contactId: number,
): Promise<IResponse> {
  const url = `${API_URLS.UPDATE_LEAD_SOURCE}?leadSourceId=${leadSourceId}&contactId=${contactId}`;

  return client.put(url);
}

async function fetchContacts(
  pageNo: number,
  pageLimit = 10,
  restUrl = "",
  roleName = "",
  search = "",
): Promise<IContactsResponse> {
  let url = `${API_URLS.FETCH_CONTACT_V2}?page=${pageNo + 1}&pageSize=${
    pageLimit || PER_PAGE
  }`;
  if (restUrl) {
    url = `${url}${restUrl}`;
  }
  // if (search) {
  //   url = `${url}&search=${search}`;
  // }
  return client.get(url);
}

async function fetchContactsAtOncce(restUrl = ""): Promise<IContactsResponse> {
  let url = `${API_URLS.FETCH_CONTACT_V2}?`;
  if (restUrl) {
    url = `${url}${restUrl}`;
  }
  return client.get(url);
}

async function fetchSignedContacts(): Promise<IResponse> {
  const url = `${API_URLS.FETCH_SIGNED_CONTACT}`;
  return client.get(url);
}

async function fetchNotes(
  pageNo: number,
  pageLimit: number,
  contactId: number,
  customerId?: any,
): Promise<IContactsResponse> {
  const url = `${API_URLS.FETCH_NOTES_PAGINATION}?page=${pageNo}&pageSize=${
    pageLimit || PER_PAGE
  }&contactId=${contactId}${customerId ? `&customerId=${customerId}` : ""}`;
  return client.get(url);
}

async function saveNote(values: any, id?: any): Promise<IResponse> {
  return id
    ? client.put(`${API_URLS.EDIT_NOTE}`, values)
    : client.post(`${API_URLS.ADD_NOTE}`, values);
}

async function deleteNote(id: any): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_NOTE}/${id}`);
}
async function getCustomFields(userId?: any): Promise<any> {
  const url = userId
    ? `${API_URLS.GET_CUSTOM_FIELDS_BY_USERID}?userid=${userId}`
    : `${API_URLS.GET_ALL_CUSTOM_FIELDS}`;
  return client.get(url);
}

async function getEmailExist(email: string): Promise<any> {
  return client.get(`${API_URLS.GET_EMAIL_EXIST}?email=${email}`);
}

async function getSearchContact(
  id: number,
  search: string,
): Promise<SearchContactRoot> {
  const url = `${API_URLS.SEARCH_CONTACT}?userId=${id}&search=${search}`;
  return client.get(url);
}

async function fetchAgreementByContactId(
  id: number,
): Promise<SearchContactRoot> {
  const url = `${API_URLS.GET_AGREEMENT_BY_CONTACT_ID}/${id}`;
  return client.get(url);
}

// AdditionalContact
async function addAdditionalContact(
  data: IAddiotionalContactRequestRoot,
): Promise<IResponse> {
  const url = `${API_URLS.ADD_ADDITIONAL_CONTACT}`;
  return client.post(url, data);
}

async function getAdditionalContact(contactId: number): Promise<any> {
  const url = `${API_URLS.GET_ADDITIONAL_CONTACT}?contactId=${contactId}`;
  return client.get(url);
}

async function updateAdditionalContact(
  data: IAddiotionalContactRequestRoot,
): Promise<IResponse> {
  const url = `${API_URLS.EDIT_ADDITIONAL_CONTACT}`;
  return client.put(url, data);
}

async function updateContactColor(data: any): Promise<IResponse> {
  const url = `${API_URLS.UPDTAE_COLOR_CONTACT}`;
  return client.put(url, data);
}

async function deleteAdditionalContact(id?: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_ADDITIONAL_CONTACT}/${id}`;
  return client.delete(url);
}

async function fetchContactAnalyticsContactId(
  id: number,
): Promise<SearchContactRoot> {
  const url = `${API_URLS.GET_CONTACT_ANALYTICS_CONTACT_ID}?contactId=${id}`;
  return client.get(url);
}
async function fetchContactByMyListId(
  userId: number,
  fromStart: boolean,
  isConnected: boolean,
): Promise<SearchContactRoot> {
  const url = `${API_URLS.GET_CONTACT_MY_LIST_ID}?userId=${userId}&fromStart=${fromStart}&isConnected=${isConnected}`;
  return client.get(url);
}

async function fetchMaxCallContact(id: number): Promise<IResponse> {
  return client.get(`${API_URLS.GET_MAX_CALL_CONTACT}/${id}`);
}

async function fetchContactDetailsForCustomer(
  contactId: number,
): Promise<IResponse> {
  return client.get(
    `${API_URLS.GET_CONTACT_DETAILS_FOR_CUSTOMER}/${contactId}`,
  );
}

export {
  addAdditionalContact,
  assignLeadOwner,
  bulkImportContact,
  changeLeadOwnerByContactId,
  changeLeadSourceByContactId,
  deleteAdditionalContact,
  deleteBulkContact,
  deleteNote,
  editContact,
  editContract,
  fetchAgreementByContactId,
  fetchContactAnalyticsContactId,
  fetchContactById,
  fetchContactByMyListId,
  fetchContactDetailsForCustomer,
  fetchContacts,
  fetchContactsAtOncce,
  fetchContactsSearch,
  fetchDuplicateContact,
  fetchMaxCallContact,
  fetchNotes,
  fetchSignedContacts,
  getAdditionalContact,
  getCustomFields,
  getEmailExist,
  getPreference,
  getSearchContact,
  removeContact,
  removeLeadOwnerById,
  saveAddress,
  saveContact,
  saveNote,
  savePreference,
  toggleInvoice,
  updateAdditionalContact,
  updateContactColor
};

