/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { useSnackbar } from "components/snackbar";
import { ORIGIN, SOCKET_URL } from "config";
import { preferencesConstants } from "constants/constants";
import { CallType } from "constants/interfaces";
import useDecodedData from "hooks/useDecodedData";
import { useIsValidTime } from "hooks/useIsValidTime";
import useUserInfo from "hooks/useUserInfo";
import useCallManager from "pages/call-manager/hook/useCallManager";
import { useAPIActions } from "pages/call-manager/query/useApiActions";
import states from "pages/call-manager/states";
import { IFilterUrl } from "pages/user/contacts/hooks/useContactListUtils";
import { INewChat } from "pages/user/inbox";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useCallManagerActions } from "redux/call-manager/callManager";
import { useChatUsersActions } from "redux/chat-users-list/chatUsersList";
import { preferenceList } from "redux/dashboard/dashboard";
import { RootState } from "redux/store";
import { fetchMaxCall } from "services/callCampaign";
import { getTwilioToken } from "services/callManager.service";
import { fetchMaxCallContact } from "services/contacts.service";
import Peer from "simple-peer";
import { Socket, io } from "socket.io-client";
import {
  CallData,
  ICallerIdWithCampaign,
  IContextProvider,
  IRefetch,
} from "socket/interface.socket";
import { SocketContext, initialValue } from "socket/object.socket";
import {
  playBusyTone,
  playOutgoingRingingSound,
  playRingingSound,
  startOutgoingRingingSound,
  stopOutgoingRingingSound,
  stopRingingSound,
} from "socket/utils.socket";
// import Device from "twilio-client/es5/twilio/device";
import { Device } from "twilio-client";
import { secondsToHms } from "utils";
import { QueryKeys } from "utils/QueryKeys";

function ContextProvider({ children }: IContextProvider) {
  const decoded = useDecodedData();
  // const updatedUrl = todayActivity
  //   ? `&RoleName=${decodedToken?.RoleName}&userid=${decodedToken.id}&columnName=Id&sort=desc&todayActivity=${todayActivity}`
  //   : `&RoleName=${decodedToken?.RoleName}&userid=${decodedToken.id}&columnName=Id&sort=desc`;

  // #region useState variables
  const [sort, setSort] = useState({ id: "", sort: "asc" });
  const [refetchBunch, handleRefetchBunch] = useState<IRefetch | null>(null);
  const [callAccepted, setCallAccepted] = useState(false);
  const [selectedCallerId, setSelectedCallerId] = useState("");
  const [chatRooms, setChatRooms] = useState<any[]>([]);
  const [newChatMessage, setNewChatMessage] = useState<INewChat | null>(null);
  const [callEnded, setCallEnded] = useState(false);
  const [isVideo, seIsVideo] = useState(false);
  const [callRejected, setCallRejected] = useState(false);
  const [cameraOn, setCameraOn] = useState(true);
  const [audioOn, setAudioOn] = useState(true);
  const [stream, setStream] = useState<MediaStream | undefined>(undefined);
  const [name, setName] = useState("");
  const [fromName, setFromName] = useState("");
  const [toUser, setToUser] = useState("");
  const [toUserName, setToUserName] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [callPopup, setCallPopup] = useState(false);
  const [voiceCallMiniPopup, setVoiceCallMiniPopup] = useState(false);
  const [userCameraOn, setUserCameraOn] = useState(true);
  const [userScreenShareOn, setUserScreenShareOn] = useState(false);
  const [openCallScreen, setOpenCallScreen] = useState(false);
  const [onConfCall, setOnConfCall] = useState(false);
  const [inComingConfCall, setInComingConfCall] = useState(false);
  const [videoConfId, setVideoConfId] = useState<any>(null);
  const [whoIsTyping, setWhoIsTyping] = useState<any>(null);
  const [twilioCallState, setTwilioCallState] = useState<any>(null);
  const [device, setDevice] = useState<any>(null);
  const [socket, setsSocket] = useState<Socket | null>(null);
  const [acceptedCall, setAcceptedCall] = useState<any>(null);
  const [screenShareEnabled, setScreenShareEnabled] = useState(false);
  const [call, setCall] = useState<CallData>(initialValue);
  const [me, setMe] = useState("");
  const myVideo = useRef<HTMLVideoElement | null>(null);
  const userVideo = useRef<HTMLVideoElement | null>(null);
  const connectionRef = useRef<Peer.Instance | null>(null);
  const [twilioCallConnection, setTwilioCallConnection] = useState<any>(null);
  const [toNumber, setToNumber] = useState("");
  const [fromNumber, setFromNumber] = useState("");
  const [incomingData, setInComingData] = useState<any>(null);
  const [callCutByClient, setCallCutByClient] = useState(false);
  const [endPopUp, setEndPopUp] = useState(false);
  const [twilioRinging, setTwilioRinging] = useState(false);
  const [callerIds, setCallerIds] = useState<string[]>([]);
  const [neverBeenContacted, setNeverBeenContacted] = useState(false);
  const [onlineUserList, setOnlineUserList] = useState<number[]>([]);
  const [signalRConnection, setSignalRConnection] = useState<string>("");
  const [callerIdWithCampaign, setCallerIdWithCampaign] = useState<
    ICallerIdWithCampaign[]
  >([]);

  const getPreferenceList: any = useSelector(preferenceList);

  const todayCallActivity = getPreferenceList?.find(
    (x: any) => x.preferenceName === preferencesConstants.todayCallActivity,
  );
  const activityOn = todayCallActivity?.preferences
    ? JSON.parse(todayCallActivity.preferences || "")
    : 1;
  const [filterUrl, setFilterUrl] = useState<IFilterUrl>({
    url: decoded
      ? `&RoleName=${decoded?.RoleName}&userid=${decoded.id}&columnName=Id&sort=desc&todayActivity=${activityOn}`
      : "",
    values: decoded
      ? [
        {
          type: "userid",
          value: decoded.id,
        },
        {
          type: "RoleName",
          value: decoded.RoleName,
        },
        {
          type: "columnName",
          value: "Id",
        },
        {
          type: "sort",
          value: "desc",
        },
        {
          type: "todayActivity",
          value: activityOn,
        },
      ]
      : [],
  });
  const setRefetchBunch = (action: IRefetch) => {
    handleRefetchBunch({ ...refetchBunch, ...action });
  };
  // const [filterUrl, setFilterUrl] = useState<IFilterUrl>({
  //   url:
  //     activityOn && data
  //       ? `&RoleName=${data?.RoleName}&userid=${data.id}&columnName=Id&sort=desc&todayActivity=1`
  //       : data
  //         ? `&RoleName=${data?.RoleName}&userid=${data.id}&columnName=Id&sort=desc`
  //         : "",
  //   values: data
  //     ? [
  //         {
  //           type: "userid",
  //           value: data.id,
  //         },
  //         {
  //           type: "RoleName",
  //           value: data.RoleName,
  //         },
  //         {
  //           type: "columnName",
  //           value: "Id",
  //         },
  //         {
  //           type: "sort",
  //           value: "desc",
  //         },
  //       ]
  //     : [],
  // });

  // useEffect(() => {
  //   console.log("useEffect", useEffect);
  //   if (data) {
  //     const request: IFilterUrl = {
  //       url:
  //         activityOn && data
  //           ? `&RoleName=${data?.RoleName}&userid=${data.id}&columnName=Id&sort=desc&todayActivity=1`
  //           : data
  //             ? `&RoleName=${data?.RoleName}&userid=${data.id}&columnName=Id&sort=desc`
  //             : "",
  //       values: data
  //         ? [
  //             {
  //               type: "userid",
  //               value: data.id,
  //             },
  //             {
  //               type: "RoleName",
  //               value: data.RoleName,
  //             },
  //             {
  //               type: "columnName",
  //               value: "Id",
  //             },
  //             {
  //               type: "sort",
  //               value: "desc",
  //             },
  //           ]
  //         : [],
  //     };
  //     setFilterUrl(request);
  //   }
  // }, [data, activityOn]);
  // #endregion useState variables

  const callManagerData = useCallManager();
  const queryClient = useQueryClient();
  const { isLoggedIn } = useUserInfo();

  const { identity } = callManagerData;
  const { tryUpdateCallSid, tryHoldUnhold, tryCallEnd } = useAPIActions();

  const userData = useSelector((state: RootState) => state.user);

  const { resetCallDuration } = useChatUsersActions();

  const snackbar = useSnackbar();
  const {
    setCallManagerDialer,
    setCallInfo,
    setCallManagerDevice,
    setCallRequest,
    cleanCallManagerMessage,
  } = useCallManagerActions();
  const { handleTimeZone } = useIsValidTime();
  // const { tryUpdateConnection } = useApiActions();

  // const selectedCallerIdPreference = useMemo(
  //   () =>
  //     getPreferenceList?.find(
  //       (item: any) => item.preferenceName === "selectCallerId",
  //     ),
  //   [getPreferenceList],
  // );

  const handleOnlineUserList = (userId: number, type: string) => {
    if (type === "online") {
      setOnlineUserList((prevState) => [...prevState, userId]);
    } else {
      setOnlineUserList((prevState) => prevState.filter((id) => id !== userId));
    }
  };
  useEffect(() => {
    if (twilioCallState)
      if (twilioCallState !== states.ON_CALL) {
        if (callManagerData && decoded && incomingData?.InCommingCallSid) {
          console.log("InCommingCallSid", incomingData);
          tryCallEnd({
            fromUserId: Number(decoded.id),
            confId: callManagerData?.callInfo?.confId,
            InComingCallSid: incomingData?.InCommingCallSid,
          });
        }
      }
  }, []);

  // #region socket function
  const socketFunc = () => {
    // Initialize socket connection
    const socketIO: Socket = initializeSocketConnection();
    setsSocket(socketIO);
    // Get user media devices

    // Handle socket events
    handleSocketEvents(socketIO);
  };

  // Initialize socket connection
  const initializeSocketConnection = () => {
    return io(SOCKET_URL, {
      query: {
        url: `?env=${ORIGIN.indexOf("81") > -1 ? "production" : "development"}&userId=${decoded?.id}`,
      },
    });
  };

  // Get user media devices
  const getUserMediaDevices = (isVideo: boolean) => {
    console.log("isVideo", isVideo);
    navigator.mediaDevices
      .getUserMedia({ video: isVideo, audio: true })
      .then((stream) => {
        setStream(stream);
        if (myVideo.current) {
          myVideo.current.srcObject = stream;
        }
      })
      .catch((err) => {
        console.error("Error accessing media devices:", err);
      });
  };

  useEffect(() => {
    getUserMediaDevices(isVideo);
  }, [isVideo]);
  // Handle socket events
  const handleSocketEvents = (socketIO: Socket) => {
    // socketIO.on("connect", () => handleSocketConnect(socketIO));
    socketIO.on("me", handleMeEvent);
    socketIO.on("share_screen", handleShareScreenEvent);
    socketIO.on("callUser", handleCallUserEvent);
    socketIO.on("callRejected", handleCallRejectedEvent);
    socketIO.on("videoOnoff", handleVideoOnOffEvent);
    socketIO.on("callEnded", handleCallEndedEvent);
    socketIO.on("callAccepted", handleCallAcceptedEvent);
    socketIO.on("leave-call", handleLeaveCallEvent);
    socketIO.on("cancel-conf", handleCancelConfEvent);
    socketIO.on("decline-conf", handleDeclineConfEvent);
    socketIO.on("start-conf", handleStartConfEvent);

    // chat events
    socketIO.on("sendMessage", handleChatMessage);
    socketIO.on("disconnect", () => {
      console.log("handle disconnected");
    });
  };

  const handleChatMessage = (data: any) => {
    console.log("handleChatMessage", data);
  };

  // Define event handler functions
  // const handleSocketConnect = (socket: any) => {
  //   if (data && data?.RoleName !== "Client") {
  //     tryUpdateConnection({
  //       userId: Number(data?.id),
  //       connectionId: socket.id,
  //     });
  //   }
  // };

  const handleMeEvent = (id: string) => {
    setMe(id);
  };

  const handleShareScreenEvent = (data: any) => {
    setUserScreenShareOn(data.isShare);
  };

  const handleCallUserEvent = (data: CallData) => {
    playRingingSound();
    const {
      userToCall,
      from,
      name: callerName = fromName,
      signal,
      isVideoCall,
      fromId,
      toUserId,
    } = data;
    setToUser(from);
    seIsVideo(isVideoCall);
    setUserCameraOn(isVideoCall);
    const callValues = {
      userToCall,
      isReceivingCall: true,
      from,
      name: callerName || fromName,
      signal,
      isVideoCall,
      fromId,
      toUserId,
    };
    setCall(callValues);
  };

  const handleCallRejectedEvent = () => {
    stopRingingSound();
    reset();
  };

  const handleVideoOnOffEvent = (data: any) => {
    setUserCameraOn(data.userCameraOn);
  };

  const handleCallEndedEvent = (body: any) => {
    if (body.action === "reject") {
      playBusyTone();
      setCallRejected(true);
      stopRingingSound();
      setCallEnded(true);
      setCall(initialValue);
      stopOutgoingRingingSound();
      resetCallDuration();
      setOpenCallScreen(false);
    } else {
      setCallEnded(true);
      resetCallDuration();
      setOpenCallScreen(false);
    }
    stopScreenShare();
    reset();
  };

  const handleCallAcceptedEvent = () => {
    stopRingingSound();
    stopOutgoingRingingSound();
  };

  const handleLeaveCallEvent = () => {
    stopRingingSound();
    setVideoConfId(null);
    setInComingConfCall(false);
    setOnConfCall(false);
    reset();
  };

  const handleCancelConfEvent = () => {
    stopRingingSound();
    setVideoConfId(null);
    setInComingConfCall(false);
    setOnConfCall(false);
  };

  const handleDeclineConfEvent = () => {
    playBusyTone();
    setVideoConfId(null);
    setInComingConfCall(false);
    setOnConfCall(false);
  };

  const handleStartConfEvent = (data: any) => {
    playRingingSound();
    setVideoConfId(data);
    setInComingConfCall(true);
  };

  // #endregion socket function

  const reset = () => {
    setCallAccepted(false);
    setCallRejected(false);
    setCallEnded(false);
    seIsVideo(false);
    setCameraOn(true);
    setAudioOn(true);
    setStream(undefined);
    setName("");
    setFromName("");
    setToUser("");
    setToUserName("");
    stopRingingSound();
    setSeconds(0);
    setCallPopup(false);
    setVoiceCallMiniPopup(false);
    setUserCameraOn(true);
    setOpenCallScreen(false);
    setOnConfCall(false);
    setInComingConfCall(false);
    setVideoConfId(null);
    setCall(initialValue);
    setSelectedCallerId("");
    setToNumber("");
    setFromNumber("");
    setInComingData(null);
    setCallCutByClient(false);
    setEndPopUp(false);
    myVideo.current = null;
    userVideo.current = null;
    stopRingingSound();
    stopOutgoingRingingSound();
    socketFunc();
    handleRefetchBunch(null);
  };
  const twilioDevice = new Device();

  const initTwilio = async () => {
    if (decoded) {
      const responseTwiML: any = await getTwilioToken({
        identity: decoded?.Email,
      });
      const token = responseTwiML?.token || "";
      if (token) {
        twilioDevice.setup(token);
        twilioDevice.on("ready", () => {
          setDevice(twilioDevice);
          setCallManagerDevice({ device });
          // if (twilioCallState !== states.ON_CALL) {
          //   setTwilioCallState(states.READY);
          // }
        });
      }
      if (twilioDevice) {
        twilioDevice.on("connect", (connection: any) => {
          setToNumber(connection?.message?.To);
          setFromNumber(connection?.message?.from);
          cleanCallManagerMessage();
          if (
            connection?.parameters?.CallSid &&
            !connection?.message?.InCommingCallSid
          ) {
            tryUpdateCallSid({
              userId: Number(decoded.id),
              callSid: connection?.parameters?.CallSid,
            });
          }
          if (callManagerData?.callType === "") {
            setTwilioCallState(states.ON_CALL);
            setTwilioRinging(false);
          }
          setEndPopUp(true);
          setTwilioCallConnection(connection);
        });
        twilioDevice.on("disconnect", () => {
          twilioDevice.disconnectAll();
          setTwilioCallConnection(null);

          // setTwilioCallState(states.READY);
        });
        twilioDevice.on("cancel", () => {
          twilioDevice.disconnectAll();
          queryClient.invalidateQueries([QueryKeys.unreadCount]);

          setTwilioCallConnection(null);
          setTwilioCallState(states.READY);
        });
        twilioDevice.on("incoming", async (connection: any) => {
          const conId = new Date().getTime().toString();
          setToNumber(connection?.parameters?.From);
          setFromNumber(
            `${connection?.message?.CallerId}${connection?.message?.To}`,
          );
          setInComingData({
            ...connection?.parameters,
            ...connection?.message,
            confId: conId,
            To: `${connection?.message?.CallerId}, ${connection?.parameters?.To}`,
          });
          setTwilioCallState(states.INCOMING);
          setTwilioCallConnection(connection);
          // const response = await fetchCallInfo(Number(data.id));
          setCallInfo({
            callInfo: {
              ...callManagerData?.callInfo,
              confId: conId,
            },
          });
        });

        twilioDevice.on("reject", () => {
          twilioDevice.disconnectAll();

          // setTwilioCallState(states.READY);
          setTwilioCallConnection(null);
        });

        twilioDevice.on("error", (error: any) => {
          // eslint-disable-next-line no-console
          console.log("error", error);
          twilioDevice.disconnectAll();
        });
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      try {
        socketFunc();
        initTwilio();
      } catch (e) {
        console.error("something went wrong with initTwilio/socketFunc:", e);
      }
    } else {
      socket?.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (stream && isVideo) {
      stream.getVideoTracks().forEach((track, index) => {
        stream.getVideoTracks()[index].enabled = true;
      });
      // setCameraOn(isVideo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callPopup, isVideo, myVideo.current]);

  // #region node socket functions
  const sendMessage = useCallback(
    (chatData: any) => {
      const filesData: any[] = [];

      if (chatData?.files?.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < chatData?.files?.length; i++) {
          const file = chatData?.files[i];
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          reader.onload = () => {
            if (reader?.result) {
              const newResult: any = reader?.result;
              const data: any = new Uint8Array(newResult);
              filesData.push({ filename: file.name, data });

              if (filesData.length === chatData?.files?.length) {
                socket?.emit("sendMessage", { ...chatData, filesData });
              }
            }
          };

          reader.onerror = (err) => {
            console.error("Error reading file:", err);
          };
        }
      } else {
        socket?.emit("sendMessage", filesData);
      }
    },
    [socket],
  );

  const switchCamera = useCallback(
    (toUser: string) => {
      if (stream) {
        stream.getVideoTracks().forEach((track, index) => {
          stream.getVideoTracks()[index].enabled = true;
        });
        socket?.emit("videoOnoff", {
          connectionId: toUser,
          userCameraOn: true,
        });

        setCameraOn(true);
      }
    },
    [stream],
  );

  const switchCameraOff = useCallback(
    (toUser: string) => {
      if (stream) {
        setCameraOn(false);
        socket?.emit("videoOnoff", {
          connectionId: toUser,
          userCameraOn: false,
        });
        stream.getVideoTracks().forEach((track, index) => {
          stream.getVideoTracks()[index].enabled = false;
        });
      }
    },
    [stream],
  );

  const switchAudioOn = useCallback(() => {
    if (stream) {
      setAudioOn(true);
      stream.getAudioTracks().forEach((track, index) => {
        stream.getAudioTracks()[index].enabled = true;
      });
      if (myVideo.current) {
        const newStream = new MediaStream(stream);
        myVideo.current.srcObject = newStream;
      }
    }
  }, [stream]);

  const switchAudioOff = useCallback(() => {
    if (stream) {
      stream.getAudioTracks().forEach((track, index) => {
        stream.getAudioTracks()[index].enabled = false;
      });

      if (myVideo.current) {
        const newStream = new MediaStream(stream);
        myVideo.current.srcObject = newStream;
      }
      setAudioOn(false);
    }
  }, [stream]);

  const answerCall = useCallback(() => {
    setCallAccepted(true);
    stopRingingSound();
    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on("signal", (data: any) => {
      socket?.emit("callAccepted", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream: any) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });

    peer.signal(call.signal);

    connectionRef.current = peer; // Set the connectionRef to the peer instance
  }, [call, socket]);

  const callUser = useCallback(
    (
      id: string,
      toUserId: number,
      isVideoCall = true,
      fromUserName?: string,
    ) => {
      startOutgoingRingingSound();

      navigator.mediaDevices
        .getUserMedia({ video: isVideoCall, audio: true })
        .then((stream) => {
          if (myVideo.current) myVideo.current.srcObject = stream;
          const peer = new Peer({ initiator: true, trickle: false, stream });
          setStream(stream);
          setToUser(id);
          seIsVideo(isVideoCall);
          setCallRejected(false);
          playOutgoingRingingSound();
          let requestData: any = null;
          peer.on("signal", async (data: any) => {
            const request: any = {
              userToCall: id,
              signalData: data,
              from: me,
              name: fromUserName || fromName,
              isVideoCall,
              isReceivingCall: true,
              fromId: decoded.id,
              toUserId,
            };
            setCall(request);
            if (!requestData?.name) await socket?.emit("callUser", request);

            requestData = request;
          });

          peer.on("stream", (currentStream: MediaStream) => {
            if (userVideo.current) userVideo.current.srcObject = currentStream;
          });

          socket?.on("callAccepted", (signal: any) => {
            stopRingingSound();
            setCallAccepted(true);
            peer?.signal(signal);
            // peer.signal(signal);
          });

          connectionRef.current = peer;
          if (myVideo.current) {
            myVideo.current.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error("Error accessing media devices:", err);
        });
    },
    [me, socket],
  );

  const shareScreen = async (isShare: boolean) => {
    let screenMediaPromise;
    if (isShare) {
      if (navigator.mediaDevices.getDisplayMedia) {
        screenMediaPromise = navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
      } else {
        screenMediaPromise = navigator.mediaDevices.getUserMedia({
          // @ts-ignore
          video: { mediaSource: "screen" },
        });
      }
    } else {
      screenMediaPromise = navigator.mediaDevices.getUserMedia({ video: true });
    }

    screenMediaPromise
      .then(async (shareStream: any) => {
        setScreenShareEnabled(isShare);
        await socket?.emit("share_screen", {
          userToCall: me === call?.from ? call.userToCall : call?.from,
          isShare,
        });
        setTimeout(() => {
          if (connectionRef.current && stream) {
            // eslint-disable-next-line no-param-reassign
            shareStream.getVideoTracks()[0].enabled = true;
            const newStream = new MediaStream([
              // eslint-disable-next-line no-param-reassign
              shareStream.getVideoTracks()[0],
            ]);
            connectionRef.current.replaceTrack(
              connectionRef.current?.streams?.[0]?.getVideoTracks()[0],
              shareStream.getVideoTracks()[0],
              connectionRef.current?.streams[0],
            );
            if (myVideo.current) {
              myVideo.current.srcObject = newStream;
            }
          }

          // eslint-disable-next-line no-param-reassign
          shareStream.getVideoTracks()[0].onended = function () {
            if (isShare) {
              setScreenShareEnabled(false);
              stopScreenShare();
            }
          };
        }, 1000);
      })
      .catch((e) => {
        alert(`Unable to share screen:${e.message}`);
        console.error(e);
      });
  };

  const stopScreenShare = () => {
    if (screenShareEnabled) {
      const screenMediaPromise = navigator.mediaDevices.getUserMedia({
        // @ts-ignore
        video: { mediaSource: "screen" },
      });
      screenMediaPromise
        .then(async (shareStream: any) => {
          setScreenShareEnabled(false);
          await socket?.emit("share_screen", {
            userToCall: me === call?.from ? call.userToCall : call?.from,
            isShare: false,
          });
          if (connectionRef.current && stream) {
            // eslint-disable-next-line no-param-reassign
            shareStream.getVideoTracks()[0].enabled = true;
            const newStream = new MediaStream([
              // eslint-disable-next-line no-param-reassign
              shareStream.getVideoTracks()[0],
            ]);
            connectionRef.current.replaceTrack(
              connectionRef.current?.streams?.[0]?.getVideoTracks()[0],
              shareStream.getVideoTracks()[0],
              connectionRef.current?.streams[0],
            );
            if (myVideo.current) {
              myVideo.current.srcObject = newStream;
            }
          }

          // for (const key in connections) {
        })
        .catch((e) => {
          // alert(`Unable to share screen:${e.message}`);
          console.error(e);
        });
    }
  };

  const makeConfCall = useCallback(
    (data: any) => {
      setVideoConfId(data);
      socket?.emit("start-conf", data);
    },
    [socket],
  );

  const leaveCall = useCallback(() => {
    setCallEnded(true);
    //
    socket?.emit("callEnded", { to: call.from, from: toUser });
    // socket.disconnect();
    setCameraOn(false);
    setAudioOn(true);
    resetCallDuration();
    reset();
    stopScreenShare();

    // window.location.reload();
  }, [socket, call.from, toUser]);

  const rejectCall = useCallback(() => {
    setCallRejected(true);
    stopRingingSound();
    socket?.emit("callEnded", {
      to: call.from,
      from: toUser,
      action: "reject",
    });
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call.from, socket]);

  const rejectConf = useCallback(
    (roomId: any, from: string) => {
      socket?.emit("decline-conf", { roomId, from });

      stopRingingSound();
      setVideoConfId(null);
      setInComingConfCall(false);
    },
    [socket],
  );

  const acceptConfCall = () => {
    setOnConfCall(true);
    stopRingingSound();
  };

  // #endregion node socket functions
  //  #region twilio call functions start
  const handleOutGoingCall = async (
    to: string,
    toId: number,
    toType: string,
    clientNumbers?: string[],
    numbers?: string[],
    type?: string,
    myListId?: number,
    fullName?: any,
  ) => {
    setVoiceCallMiniPopup(false);
    if (!to) {
      snackbar?.show({
        title: "Phone No not available",
        type: "error",
      });
      return false;
    }

    const callGapMax =
      toType === "Contact"
        ? await fetchMaxCallContact(toId)
        : await fetchMaxCall(toId, to);

    if (callGapMax.data?.maxCall) {
      snackbar?.show({
        title: callGapMax.message,
        type: "error",
      });
      return false;
    }

    if (callGapMax.data?.callGap) {
      snackbar?.show({
        title: `${callGapMax.message}, you can call after ${secondsToHms(callGapMax?.data?.callGapSeconds)}`,
        type: "error",
      });
      return false;
    }
    if (callGapMax.message === "this phone number is already set to DNC") {
      snackbar?.show({
        title: "this phone number is already set to DNC",
        type: "error",
      });
      return false;
    }

    const callerId = selectedCallerId;
    // selectedCallerIdPreference?.preferences
    //   ? JSON.parse(selectedCallerIdPreference?.preferences)
    //   : "";
    const conId = new Date().getTime().toString();
    const isValidTime = handleTimeZone(to);
    if (!callerId) {
      snackbar?.show({
        title: "Please select caller id.",
        type: "error",
      });
    } else if (isValidTime) {
      //
      setTwilioRinging(true);
      const callRequest = {
        To: to,
        callingDeviceIdentity: identity,
        from: callerId,
        userId: decoded.id,
        companyId: userData?.companyId,
        campId: decoded?.campaignId || 0,
        system_direction: "outbound",
        conferenceId: conId,
        toId: toId || 0,
        toType: toType || "other",
        numbers: numbers?.toString(),
        type,
        myListId: myListId || 0,
        fullName,
        clientNumbers,
      };
      device.connect(callRequest);
      setCallRequest({ callRequest });
      setCallInfo({
        callInfo: { ...callManagerData?.callInfo, confId: conId },
      });
      setTwilioRinging(false);
      setCallManagerDialer({ dialer: false });
      cleanCallManagerMessage();
    }

    return true;
  };

  const handleOutGoingCallHangup = async (
    type: CallType,
    leftConference: boolean,
  ) => {
    setVoiceCallMiniPopup(false);
    if (type === "call-transfer") {
      await tryHoldUnhold({
        confId: callManagerData?.callInfo.confId,
        action: "hold",
        userId: Number(decoded.id),
      });
    }
    if (type === "hold") {
      await tryHoldUnhold({
        confId: callManagerData?.callInfo.confId,
        action: "hold",
        userId: Number(decoded.id),
      });
    }
    if (type === "unhold") {
      await tryHoldUnhold({
        confId: callManagerData?.callInfo.confId,
        action: "unhold",
        userId: Number(decoded.id),
      });
    }
    if (type === "end") {
      device?.disconnectAll();
      console.log("leftConference", leftConference);
      await tryCallEnd({
        fromUserId: Number(decoded.id),
        confId: callManagerData?.callInfo?.confId,
        InComingCallSid: incomingData?.InCommingCallSid,
        leftConference,
      });
      if (incomingData && twilioCallState === states.READY) {
        setTwilioCallState(states.READY);
      }
      setInComingData(null);
    }
    if (type === "mute") {
      twilioCallConnection.mute(true);
    }
    if (type === "unmute") {
      twilioCallConnection.mute(false);
    }
  };

  const handleDTMFNum = (num: string) => {
    twilioCallConnection.sendDigits(num);
  };

  const handleInAcceptComingCall = () => {
    twilioCallConnection.accept();
  };

  const handleLeaveConf = async () => {
    device.disconnectAll();
  };

  // #endregion twilio call functions end

  const allValues = useMemo(() => {
    return {
      onlineUserList,
      setOnlineUserList,
      handleOnlineUserList,
      callerIdWithCampaign,
      setCallerIdWithCampaign,
      reset,
      selectedCallerId,
      setSelectedCallerId,
      neverBeenContacted,
      setNeverBeenContacted,
      sort,
      setSort,
      refetchBunch,
      setRefetchBunch,
      filterUrl,
      setFilterUrl,
      chatRooms,
      call,
      callAccepted,
      myVideo,
      userVideo,
      openCallScreen,
      stream,
      name,
      setName,
      fromName,
      setFromName,
      cameraOn,
      setCameraOn,
      audioOn,
      setAudioOn,
      switchAudioOn,
      switchAudioOff,
      callEnded,
      callRejected,
      me,
      toUser,
      toUserName,
      callUser,
      leaveCall,
      makeConfCall,
      answerCall,
      switchCamera,
      switchCameraOff,
      rejectCall,
      setToUserName,
      isVideo,
      seconds,
      setSeconds,
      callPopup,
      voiceCallMiniPopup,
      setVoiceCallMiniPopup,
      setCallPopup,
      userCameraOn,
      setOpenCallScreen,
      onConfCall,
      setOnConfCall,
      videoConfId,
      setVideoConfId,
      inComingConfCall,
      setInComingConfCall,
      rejectConf,
      acceptConfCall,
      acceptedCall,
      setAcceptedCall,
      whoIsTyping,
      setWhoIsTyping,
      twilioCallState,
      setTwilioCallState,
      device,
      setDevice,
      twilioCallConnection,
      setTwilioCallConnection,
      toNumber,
      setToNumber,
      fromNumber,
      setFromNumber,
      incomingData,
      setInComingData,
      callCutByClient,
      setCallCutByClient,
      endPopUp,
      setEndPopUp,
      handleOutGoingCallHangup,
      handleOutGoingCall,
      twilioRinging,
      setTwilioRinging,
      handleDTMFNum,
      handleInAcceptComingCall,
      handleLeaveConf,
      callerIds,
      setCallerIds,
      shareScreen,
      screenShareEnabled,
      setScreenShareEnabled,
      signalRConnection,
      setSignalRConnection,
      userScreenShareOn,
      setUserScreenShareOn,
      sendMessage,
      setChatRooms,
      setNewChatMessage,
      newChatMessage,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onlineUserList,
    setOnlineUserList,
    handleOnlineUserList,
    callerIdWithCampaign,
    setCallerIdWithCampaign,
    reset,
    selectedCallerId,
    setSelectedCallerId,
    neverBeenContacted,
    setNeverBeenContacted,
    sort,
    setSort,
    refetchBunch,
    setRefetchBunch,
    filterUrl,
    setFilterUrl,
    chatRooms,
    call,
    callAccepted,
    stream,
    name,
    cameraOn,
    audioOn,
    switchAudioOn,
    switchAudioOff,
    callEnded,
    callRejected,
    me,
    toUser,
    callUser,
    userCameraOn,
    leaveCall,
    makeConfCall,
    answerCall,
    switchCamera,
    switchCameraOff,
    rejectCall,
    callPopup,
    setCallPopup,
    setCallRejected,
    setOpenCallScreen,
    onConfCall,
    setOnConfCall,
    videoConfId,
    setVideoConfId,
    inComingConfCall,
    setInComingConfCall,
    rejectConf,
    whoIsTyping,
    setWhoIsTyping,
    twilioCallState,
    setTwilioCallState,
    device,
    setDevice,
    twilioCallConnection,
    setTwilioCallConnection,
    toNumber,
    setToNumber,
    fromNumber,
    setFromNumber,
    incomingData,
    setInComingData,
    callCutByClient,
    setCallCutByClient,
    endPopUp,
    setEndPopUp,
    handleOutGoingCallHangup,
    handleOutGoingCall,
    twilioRinging,
    setTwilioRinging,
    handleDTMFNum,
    handleInAcceptComingCall,
    handleLeaveConf,
    shareScreen,
    screenShareEnabled,
    setScreenShareEnabled,
    signalRConnection,
    setSignalRConnection,
    userScreenShareOn,
    sendMessage,
    setChatRooms,
    setNewChatMessage,
    newChatMessage,
  ]);
  //  #region socket end
  return (
    <SocketContext.Provider value={allValues}>
      {children}
    </SocketContext.Provider>
  );
}

export { ContextProvider, SocketContext };

